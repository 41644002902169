@import 'styles/_variables';
@import 'styles/_mixins';

.m-team {
  width: 100%;
  margin: auto;

  &__item {
    max-width: 360px;
    background-color: #ffffff;
    box-shadow: 0 20px 19px rgba(0, 0, 0, 0.05);
    overflow: hidden;
    margin: 0 auto 60px;

    &--image {
      overflow: hidden;
      border-radius: 30px 0 0 0;

      img {
        max-width: 100%;
      }
    }

    &--info {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 60px 25px;
      text-align: center;

      @include media-breakpoint-down(lg) {
        padding: 60px 15px;
      }

      strong {
        color: #2d3c53;
        font-size: 30px;
        font-weight: 600;
        line-height: 45px;
      }

      b {
        color: #18a1db;
        font-size: 18px;
        font-weight: 600;
        @include media-breakpoint-down(lg) {
          font-size: 16px;
        }
      }

      span {
        color: #202233;
        font-size: 18px;
        font-weight: 500;
        line-height: 30px;
      }
    }
  }

  .owl-dots {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    .owl-dot {
      margin: 0 5px;
      width: 28px;
      height: 28px;
      background-color: #ededed;
      border-radius: 100%;
      display: inline-block;
      border: 3px solid transparent;

      &.active {
        background: $colorPrimary;
        border-color: $colorPrimary;
      }
    }
  }
}
