@import 'styles/_variables';
@import 'styles/_mixins';

.o-ourSponsors {
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 50%,
    rgba(52, 172, 223, 1) 50%
  );
  text-align: center;
  @include media-breakpoint-down(md) {
    padding-bottom: 30px !important;
  }
  @include media-breakpoint-down(sm) {
    padding-bottom: 0 !important;
  }

  .a-title {
    color: #fff;
  }

  &__logos {
    margin-top: 50px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    @include media-breakpoint-down(sm) {
      margin-top: 20px;
    }
    &--item {
      img {
        max-width: 100%;
      }
      @include media-breakpoint-down(md) {
        padding: 15px 20px;
      }
      @include media-breakpoint-down(sm) {
        padding: 15px 10px;
      }
    }
  }

  &__carousel {
    margin-top: 50px;
    position: relative;
    @include media-breakpoint-down(sm) {
      margin-top: 20px;
    }
  }
}
