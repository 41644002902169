@import 'styles/_variables';
@import 'styles/_mixins';

.m-availabilityTable {
  width: 100%;

  table {
    width: 100%;
    max-width: 1170px;
    margin: auto;
    box-shadow: 0 0 46px rgba(0, 0, 0, 0.09);
    background-color: #ffffff;

    thead {
      tr {
        th {
          padding: 15px 30px;
          background-color: #eee;
          color: #14132a;
          font-size: 30px;
          font-weight: 600;
          text-align: left;
          &:last-child {
            text-align: right;
          }

          @include media-breakpoint-down(xs) {
            padding: 10px;
            font-size: 18px;
          }
        }
      }
    }

    tbody {
      td {
        color: #545468;
        font-size: 22px;
        font-weight: 400;
        padding: 20px 30px;
        border-bottom: 1px solid #eee;
        @include media-breakpoint-down(sm) {
          font-size: 16px;
          padding: 15px;
        }
        &:last-child {
          text-align: right;
        }
      }
    }
  }
}
