@import 'styles/_variables';
@import 'styles/_mixins';

.a-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;

  &__container {
    background: #fff;
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    border-radius: 10px;
    overflow: hidden;
    @include media-breakpoint-down(xs){
      margin: 0 15px;
    }
  }

  &__header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px;
    line-height: 1;
    flex-direction: column;
    position: relative;

    h3 {
      font-weight: 500;
      font-size: 25px;
      width: 100%;
      text-align: center;
    }

    svg {
      width: 45px;
      height: auto;
      margin-bottom: 15px;

      path {
        fill: #499fd7;
      }
    }

    &--close {
      position: absolute;
      top: 15px;
      right: 15px;

      svg {
        width: 22px;
        height: 22px;
        margin-bottom: 0;

        path {
          fill: #26395a;
        }
      }
    }
  }

  &__content {
    width: 100%;
    padding: 0 30px 30px;
    @include media-breakpoint-down(xs){
      padding: 0 15px 30px;
    }
  }

  &__actions {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;

    &--cancelButton,
    &--submitButton {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 8px;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      color: #499fd7;
      border: 2px solid #499fd7;
      border-radius: 100px;
      min-width: 140px;
      padding: 8px;
      text-transform: uppercase;
    }

    &--submitButton {
      color: #fff;
      background: #499fd7;
    }
  }

  &__markAsCompleted {
    &--markedAsComplete {
      width: 100%;
      text-align: center;
      margin-top: 15px;
      border: 1px solid #ccc;
      border-radius: 10px;
      padding: 16px;
    }

    .p-manageCase__content--paymentInfo {
      margin: 0;
      width: 100%;
      padding: 0;
    }

    .-text {
      margin-bottom: 32px;
      line-height: 1.2;
    }

    small {
      font-size: 12px;
      margin-top: 15px;
      width: 100%;
      display: flex;
      border: 1px solid #ccc;
      border-radius: 10px;
      padding: 10px;
    }

    &--content {
      width: 100%;
      display: flex;
      justify-content: space-between;
      @include media-breakpoint-down(sm) {
        flex-direction: column;
      }

      small {
        &.-gray {
          margin-top: 5px;
          color: #777;
        }
      }

      &-card {
        width: 100%;
        max-width: 50%;
        @include media-breakpoint-down(md) {
          max-width: 60%;
        }
        @include media-breakpoint-down(sm) {
          max-width: 100%;
          margin-bottom: 30px;
        }
      }

      &-actions {
        width: 100%;
        max-width: calc(50% - 30px);
        display: flex;
        flex-direction: column;
        @include media-breakpoint-down(md) {
          max-width: calc(40% - 30px);
        }
        @include media-breakpoint-down(sm) {
          max-width: 100%;
        }
      }

      &-paymentInfo {
        display: flex;
        flex-direction: column;
        padding-top: 24px;
      }

      &-calculation {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 0;
        &.-start {
          width: 100%;
          align-items: flex-start;
          .flex{
            width: 100%;
          }
        }

        &:not(:last-child) {
          border-bottom: 1px solid #eee;
        }

        &:last-child {
          padding-bottom: 0;
        }

        p {
          color: #939393;
          font-size: 16px;
          font-weight: 400;
        }

        > span {
          color: #939393;
          font-size: 16px;
          font-weight: 400;
        }

        @include media-breakpoint-down(xs) {
          p,
          > span {
            font-size: 14px;
          }
        }
      }
    }
  }

  &__reviewCase,
  &__reportADispute {
    display: flex;
    flex-direction: column;

    header {
      width: 100%;
      display: flex;
      justify-content: space-between;

      label {
        font-weight: 600;
        font-size: 15px;
        margin-bottom: 16px;
      }

      small {
        color: #999;
      }
    }

    textarea {
      outline: none;
      width: 100%;
      height: 120px;
      border: 1px solid #a0a3bd;
      border-radius: 16px;
      padding: 8px 16px;

      &:focus {
        border: 1px solid #26395a;
      }
    }
  }

  &__reviewCase{
    &--form{
      &-stars{
        display: flex;
        align-items: center;
        margin-top: 16px;
        line-height: 1;
        span{
          padding-top: 4px;
          margin-right: 16px;
        }
      }
    }
  }

  &__removeOffer {
    p {
      width: 100%;
      text-align: center;
    }
  }

  &__requestExtension {
    .-form {
      width: 100%;

      .-field {
        width: 100%;
      }
    }
  }

  &__createOffer {
    position: relative;
    min-height: 150px;
    overflow: hidden;

    &--form {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      &-field {
        width: 100%;

        &.-paymentType {
          max-width: calc(60% - 15px);
        }

        &.-amount {
          max-width: calc(40% - 15px);
        }

        @include media-breakpoint-down(xs) {
          &.-amount,
          &.-paymentType {
            max-width: 100%;
          }
        }

        &.-total,
        &.-estimatedHours,
        &.-completionDate {
          display: flex;
          width: 100%;
          align-items: center;

          > span {
            width: 100%;
            max-width: calc(60% + 15px);
            display: block;
            @include media-breakpoint-down(xs) {
              font-size: 14px;
            }
          }
        }

        &.-total,
        &.-estimatedHours {
          text-align: right;

          > span {
            width: 100%;
            max-width: calc(60% + 15px);
            padding-right: 30px;
            @include media-breakpoint-down(xs) {
              padding-right: 15px;
            }
          }
        }
      }

      &-calculation {
        width: 100%;
        padding-bottom: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:not(.-legalAid) {
          padding: 15px 0;
          border-bottom: 1px solid #eee;
        }

        p {
          color: #a2a2a2;
          font-size: 16px;
          font-weight: 400;
        }

        > span {
          color: #a2a2a2;
          font-size: 16px;
          font-weight: 400;
        }

        @include media-breakpoint-down(xs) {
          p,
          > span {
            font-size: 14px;
          }
        }
      }
    }
    p {
      font-size: 14px;
      color: #777;
    }
    small {
      width: 50px;
      text-align: center;
      color: #999;
      position: absolute;
      bottom: -8px;
      right: 8px;
      background: #fff;
    }
    .a-modal__actions {
      width: 100%;
    }
  }

  &.-emptyMode {
    .a-modal {
      &__container {
        -webkit-background-clip: padding-box;
        -webkit-backface-visibility: hidden;
        -webkit-transform: translate3d(0, 0, 0);
        outline: none;
        border: none;
        text-decoration: none;
      }

      &__header {
        display: none;
      }

      &__content {
        padding: 0;
      }
    }
  }

  .-message {
    width: 100%;
    margin-top: 15px;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 16px;
    &.-textCenter{

    }
  }
}
