@import 'styles/_variables';
@import 'styles/_mixins';


.m-practiceAreas {

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;

    button {
      svg {
        width: 22px;
        height: 22px;
        color: #26395A;
      }
    }

    span {
      font-weight: 500;
      font-size: 15px;
      color: #26395A;
    }
  }

  &__main {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;
    overflow-y: auto;
    max-height: 200px;

    .divider {
      width: 100%;
      margin: 15px 5px 10px;
      border-bottom: 1px solid rgba(5, 14, 48, 0.2);
      padding-bottom: 5px;
    }


    .chip{
      font-weight: 500;
      font-size: 12px;
      color: #050E30;
      display: flex;
      align-items: center;
      justify-content: center;

      background: #E1E1E1;
      border-radius: 40px;
      padding: 4px 8px;
      margin: 5px;
      svg{
        margin-right: 4px;
      }
    }
  }
}
