@import 'styles/_variables';
@import 'styles/_mixins';

.m-chatCamera {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;

  &.-hide {
    display: none;
  }

  &__loading {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    background: rgba(255, 255, 255, 0.8);
  }

  &__content {
    position: relative;
    background: #fff;
    padding: 15px;
    box-shadow: 0 0 29px rgba(0, 0, 0, 0.16);
    border-radius: 15px;
    &--cameraClose {
      position: absolute;
      top: 30px;
      right: 30px;
      z-index: 10;
      background: rgba(255, 255, 255, 0.8);
      padding: 10px;
      border-radius: 5px;
    }
    #outer-circle {
      cursor: pointer;
      #inner-circle {
        transition: all 0.2s linear;
      }
      &:hover {
        #inner-circle {
          background: $colorPrimary;
        }
      }
    }
    &--camera,
    &--image {
      overflow: hidden;
      border-radius: 10px;
    }
    &--actions {
      position: absolute;
      bottom: 30px;
      left: 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
      button {
        background: $colorPrimary;
        padding: 10px 25px;
        color: #fff;
        margin: 0 10px;
        font-size: 14px;
        border-radius: 5px;
        transition: all 0.2s linear;
        &:hover {
          background: #11729b;
        }
        &.-changePhoto {
          background: #fff;
          color: #666;
          &:hover {
            background: #eee;
          }
        }
      }
    }
  }
}
