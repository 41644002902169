@import 'styles/_variables';
@import 'styles/_mixins';

.m-lawyerCard {
  border-radius: 10px;
  background-color: #f8f9ff;
  width: 100%;
  padding: 15px;
  display: flex;
  flex-direction: column;
  border: 3px solid #f8f9ff;
  transition: all 0.2s linear;
  cursor: pointer;
  @include media-breakpoint-down(lg) {
    padding: 10px;
  }

  @include media-breakpoint-down(md) {
    max-width: 360px;
    margin: 0 auto 30px;
  }

  &:hover {
    box-shadow: 0 7px 50px rgba(187, 187, 187, 0.2);
    border-radius: 10px;
    border: 3px solid rgba(24, 161, 219, 0.2);
    background-color: #f8f9ff;
  }

  &__content {
    display: flex;
  }

  &__image {
    margin: 0 15px 15px 0;
    width: 150px;
    height: 150px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    @include media-breakpoint-down(lg) {
      min-width: 100px;
      margin-right: 10px;
    }
  }

  &__info {
    width: 100%;
    text-align: left;

    &--title {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      svg {
        width: 20px;
        height: 20px;
      }

      strong {
        color: $colorPrimary;
        font-size: 24px;
        font-weight: 600;
        padding-right: 10px;
        line-height: 1;

        @include media-breakpoint-down(lg) {
          font-size: 18px;
          font-weight: 400;
        }
      }

      .a-lawyerStatus{
        margin-left: auto;
      }

      button {
        min-width: unset;
        padding: 0;
        margin-left: 15px;
        @include media-breakpoint-down(lg) {
          margin-left: 10px;
        }
      }
    }

    &--segment {
      font-weight: 400;
      color: #888;
      font-size: 14px;
    }

    &--rating {
      display: flex;
      align-items: center;

      span {
        color: #888;
        font-size: 12px;
        font-weight: 600;
        margin-left: 10px;
        padding-top: 5px;
      }
    }

    &--list {
      margin-top: 15px;

      > div {
        display: flex;
        margin-bottom: 5px;
        line-height: 1;

        b,
        span {
          color: #222;
          font-size: 15px;
          font-weight: 400;
        }

        b {
          font-weight: 600;
        }

        .m-lawyerCard__info--list-icon {
          width: 20px;
          margin-right: 10px;

          @include media-breakpoint-down(lg) {
            width: 15px;
            margin-right: 5px;
          }

          img {
            margin: auto;
          }
        }
      }
    }
  }

  &__extraInfo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: 5px;

    &--hourRate,
    &--fee {
      padding: 10px;
      border-radius: 5px;
      color: $colorPrimary;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $colorPrimary;
      text-align: left;
      min-width: calc(50% - 30px);
      height: 70px;
      @include media-breakpoint-down(lg) {
        height: 60px;
        font-size: 15px;
      }
      @include media-breakpoint-down(sm) {
        min-width: unset;
        white-space: nowrap;
      }

      &Icon {
        margin-right: 15px;

        @include media-breakpoint-down(lg) {
          margin-right: 10px;
        }
        @include media-breakpoint-down(sm) {
          margin-right: 5px;
        }
      }
    }

    &--divider {
      margin: auto;
      color: $colorPrimary;
      font-size: 14px;
      font-weight: 400;
      line-height: 35px;
      @include media-breakpoint-down(sm) {
        padding: 0 5px !important;
      }
    }

    &--hourRate {
      flex-direction: column;

      strong {
        font-size: 24px;
        margin-top: 3px;

        @include media-breakpoint-down(lg) {
          font-size: 18px;
          text-align: left;
        }
      }
    }
  }
}
