@import 'styles/_variables';
@import 'styles/_mixins';

.o-faqQuestions {
  &__panel {
    &--question {
      &Text {
        font-size: 18px;
        font-weight: 600;
        padding-right: 15px;
      }

      &Icon {
        width: 20px;
        height: 20px;
        min-width: 20px;
        margin-left: auto;

        path {
          fill: $colorPrimary
        }
      }

      &[aria-expanded="true"] {
        .o-faqQuestions__panel--questionIcon {
          path {
            fill: #fff
          }
        }
      }
    }
  }

  &__empty {
    width: 100%;
    text-align: center;
    padding: 30px;
  }
}
