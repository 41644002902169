@import 'styles/_variables';
@import 'styles/_mixins';

.m-getDirections {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1140px;
  padding-top: 50px;
  margin: auto;

  @include media-breakpoint-down(sm) {
    padding-top: 30px;
  }
  .o-results__map--action {
    min-height: unset !important;
  }
  .results_map {
    height: 450px;
    .infoWindow {
      width: 220px;
      position: relative;
      bottom: 170px;
      transform: translate(-50%, 0);
      background: #fff;
      box-shadow: 0 2px 17px 1px rgba(0, 0, 0, 0.3);
      padding: 15px;
      z-index: 100;
      &:before {
        content: '';
        border-top: 10px solid #fff;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translate(-50%, 0);
        z-index: 200;
      }
      strong {
        color: #222;
        font-size: 16px;
        font-weight: 700;
        padding-bottom: 5px;
        display: block;
      }
      p {
        color: #222;
        font-size: 14px;
        font-weight: 400;
        padding-bottom: 15px;
      }
      a {
        color: #18a1db;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: normal;
        text-align: left;
        text-decoration: underline;
      }
    }
  }

  @include media-breakpoint-down(md) {
    flex-direction: column;
  }

  &__info {
    width: 100%;
    &--content {
      display: flex;

      h3 {
        color: #222222;
        font-size: 45px;
        font-weight: 600;
        line-height: 55px;
        width: 100%;
        text-align: center;
        padding-bottom: 30px;

        @include media-breakpoint-down(lg) {
          font-size: 38px;
        }

        @include media-breakpoint-down(sm) {
          font-size: 26px;
          padding-bottom: 15px;
        }
      }
    }
  }

  &__map {
    width: 100%;
    .o-results__map--mobileButtons,
    .o-results__map--mobileButtons {
      display: none !important;
    }
    img {
      object-fit: cover;
    }
  }
}
