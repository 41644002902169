@import 'styles/_variables';
@import 'styles/_mixins';

.m-offerVideo {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  &__content {
    width: 100%;
    max-width: 900px;
    position: relative;
    &--video{
      max-width: 100%;
      max-height: 100%;
      video {
        width: 100%;
        height: auto;
      }
    }
    button {
      position: absolute;
      top: 15px;
      right: 15px;
      z-index: 99999;
      width: 25px;
      height: 25px;
      svg {
        width: 25px;
        height: 25px;
        path {
          fill: black;
        }
      }
      &:hover {
        svg {
          path {
            fill: #444;
          }
        }
      }
    }
  }
}
