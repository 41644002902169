@import 'styles/_variables';
@import 'styles/_mixins';

.t-mainFooter {
  width: 100%;
  padding: 50px 30px;
  background-color: #11122d;

  @include media-breakpoint-down(sm) {
    padding: 50px 20px;
  }

  &__actions {
    width: 100%;
    display: flex;
    justify-content: space-between;

    @include media-breakpoint-down(sm) {
      flex-wrap: wrap;
    }

    &--column {
      @include media-breakpoint-down(md) {
        max-width: 260px;
      }

      @include media-breakpoint-down(sm) {
        width: 100%;
        max-width: 50%;
        &:last-child {
          max-width: 100%;
          text-align: center;
          margin: 30px auto 0;

          form {
            max-width: 260px;
            margin: auto;
          }
        }
      }

      strong {
        color: #fff;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 30px;
        width: 100%;
        display: inline-block;
        @include media-breakpoint-down(sm) {
          font-size: 18px;
        }
      }

      ul {
        li {
          a {
            color: #cacaca;
            font-size: 16px;
            font-weight: 400;
            line-height: 40px;

            transition: all 0.2s linear;

            @include media-breakpoint-down(sm) {
              font-size: 14px;
              line-height: 30px;
            }

            &:hover {
              color: #18a1db;
            }
          }
        }
      }
      &.-join {
        min-width: 260px;
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          background: $colorPrimary;
          color: #fff;
          border-radius: 5px;
          font-size: 14px;
          font-weight: 600;
          font-style: normal;
          text-align: center;
          text-transform: uppercase;
          padding: 20px 30px;
          width: 100%;
          transition: all .2s linear;
          &:hover{
            background: rgb(16, 112, 153);
          }
        }
      }
    }
  }

  &__copyright {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #545474;
    padding-top: 30px;
    margin-top: 50px;

    @include media-breakpoint-down(sm) {
      border: none;
      padding-top: 50px;
      flex-direction: column;
      text-align: center;
    }

    &--text {
      margin-right: auto;
      max-width: 690px;
      color: #c9c9c9;
      font-size: 16px;
      font-weight: 400;
      line-height: 30px;
      @include media-breakpoint-down(sm) {
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 30px;
      }
    }

    &--social {
      display: flex;
      justify-content: space-between;

      a {
        margin-left: 30px;
        width: 40px;
        height: 40px;

        @include media-breakpoint-down(sm) {
          margin: 0 15px;
        }

        svg {
          width: 100%;
          height: 100%;

          path {
            transition: all 0.2s linear;
            fill: #fff;
          }
        }

        &:hover {
          svg {
            path {
              fill: #18a1db;
            }
          }
        }
      }
    }
  }
}
