@import 'styles/_variables';
@import 'styles/_mixins';

.o-whatIsLawyerup {
  background-color: #11132c;
  display: flex;
  flex-direction: column;
  position: relative;
  &:after {
    content: '';
    border-top: 40px solid #11132c;
    border-left: 40px solid transparent;
    border-right: 40px solid transparent;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
  &__header {
    text-align: center;
    margin-bottom: 30px;
    h2 {
      color: #fff;
    }
  }
  &__content {
    display: flex;
    align-items: center;
    justify-content: space-around;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
    &--text {
      color: #dfdfdf;
      font-size: 26px;
      max-width: 470px;
      text-align: center;
      @include media-breakpoint-down(md) {
        font-size: 20px;
      }
      strong {
        color: #18a1db;
        font-weight: 500;
      }
      &-button{
        display: block;
        @include media-breakpoint-down(sm){
          display: none;
        }
      }
    }
    &--image {
      max-width: 550px;
      &-button{
        display: none;
        @include media-breakpoint-down(sm){
          display: block;
          text-align: center;
        }
      }
    }
  }
}
