@import 'styles/_variables';
@import 'styles/_mixins';

.p-loginPage {
  display: flex;
  min-height: 100vh;

  @include media-breakpoint-down(sm) {
    margin-top: -30px;
  }

  &__intro {
    flex: 1;
    background: url('../../../images/bg-login.png');
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    position: relative;
    @include media-breakpoint-down(sm) {
      display: none;
    }

    &--content {
      max-width: 750px;
      padding: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        margin-bottom: 40px;
        max-width: 100%;
      }

      p {
        color: #fff;
        font-size: 20px;
        font-weight: 400;
        line-height: 32px;
      }
    }

    &--call {
      position: absolute;
      bottom: 30px;
      width: 100%;
      text-align: center;

      span {
        color: #fff;
        font-size: 20px;
        font-weight: 400;

        a {
          font-weight: 600;
          text-decoration: underline;
        }
      }
    }
  }

  &__forms {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 600px;
    max-width: 100%;

    @include media-breakpoint-down(lg) {
      width: 540px;
    }

    @include media-breakpoint-down(md) {
      width: 400px;
    }
    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    &--content {
      width: 100%;
      padding: 30px 60px;

      @include media-breakpoint-down(md) {
        padding: 30px;
      }
      @include media-breakpoint-down(sm) {
        max-width: 600px;
      }

      h1 {
        color: #222;
        font-size: 45px;
        font-weight: 600;
        text-align: center;
        @include media-breakpoint-down(sm) {
          font-size: 30px;
        }
      }
    }

    &--tabs {
      display: flex;
      justify-content: center;
      margin-top: 50px;

      button {
        color: #222;
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 0.84px;
        padding: 20px 40px;
        border: 1px solid #ddd;
        background-color: #fff;
        border-radius: 5px;
        margin: 0 10px;
        position: relative;
        outline: none !important;
        box-shadow: none;
        @include media-breakpoint-down(md) {
          padding: 20px;
        }

        i {
          display: none;
        }

        &.-selected {
          color: $colorPrimary;
          border-color: $colorPrimary;

          i {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: -15px;
            right: -15px;
            width: 30px;
            height: 30px;
            border: 1px solid $colorPrimary;
            border-radius: 100%;
            background: #fff;
          }
        }
      }
    }
  }

  &__form {
    margin-top: 40px;
    width: 100%;

    &--half {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: space-between;

      &Field {
        width: 100%;
        max-width: calc(50% - 7px);
        @include media-breakpoint-down(xs) {
          max-width: 100%;
        }
      }
    }

    &--field {
      width: 100%;
    }

    &--extra {
      display: flex;
      justify-content: space-between;
      margin-top: 15px;

      a {
        color: #888;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }

  &__social {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    align-items: center;
    width: 100%;

    &--divider {
      background-color: #f5f5f5;
      width: 45px;
      height: 45px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #222;
      font-size: 16px;
      font-weight: 400;
    }

    &--facebook {
      width: 100%;
      margin-top: 40px;

      button {
        width: 100%;
        background-color: #3b5999;
        padding: 5px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: all 0.2s linear;

        &:hover {
          background: #33487f;
        }

        i {
          border-radius: 5px;
          width: 57px;
          height: 57px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #2c4b8d;
        }

        span {
          color: #fff;
          font-size: 14px;
          font-weight: 600;
          text-transform: uppercase;
          letter-spacing: 0.84px;
          text-align: center;
          flex: 1;
        }
      }
    }

    p {
      margin-top: 25px;
      color: #888;
      font-size: 14px;
      font-weight: 400;
    }
  }

  &.-modal {
    min-height: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
    padding: 30px;
    margin: auto;
    .p-loginPage__intro {
      display: none !important;
    }
    .p-loginPage__forms {
      position: relative;
      background: #fff;
      margin: auto;
      border-radius: 15px;
      box-shadow: 0 0 10px rgba(0,0,0,.3);
      &--closeModal{
        position: absolute;
        top: 25px;
        right: 25px;
        outline: none;
        svg{
          width: 25px;
          height: 25px;
        }
      }
    }
  }
}
