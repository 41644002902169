@import 'styles/_variables';
@import 'styles/_mixins';

.m-resultsFilter {
  opacity: 0;
  position: absolute;
  top: 200px;
  left: 0;
  z-index: 99;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 25px rgba(12, 36, 56, 0.15);
  border-radius: 3px;
  background-color: #fff;
  pointer-events: none;
  transition: all .3s ease-in-out;

  &.-show {
    opacity: 1;
    pointer-events: initial;
    height: calc(100% - 70px);
    top: 20px;
  }

  &__header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #f4f5fb;
    padding: 15px 20px;

    strong {
      color: #222;
      font-size: 18px;
      font-weight: 700;
      line-height: 28px;
    }

    button {
      width: 24px;
      height: 24px;

      svg {
        path {
          fill: #e81123;
        }
      }
    }
  }

  &__content {
    padding: 10px 20px;
    display: flex;
    width: 100%;
    flex-wrap: wrap;

    &--action {
      width: 100%;
      text-align: center;

      button {
        color: #fff;
        font-size: 16px;
        font-family: $defaultFontFamily;
        font-weight: 600;
        line-height: 30px;
        text-transform: uppercase;
        letter-spacing: 0.96px;
        padding: 15px 30px;
        max-width: 330px;
        margin: auto;
      }
    }

    &--option {
      width: 100%;
      max-width: calc(50% - 15px);
      margin-bottom: 15px;

      &.-fullWidth {
        max-width: 100%;
      }
    }

    &--label {
      color: #18a1db;
      font-size: 18px;
      font-weight: 500;
      line-height: 28px;
      width: 100%;
    }

    &--field {
      &:not(.-distance) {
        display: flex;
        flex-wrap: wrap;
        white-space: nowrap;
      }

      color: #575759;
      font-size: 14px;
      font-weight: 400;
      line-height: 28px;

      &.-distance {
        padding: 0 20px 10px;
      }

      &.-hourlyRate {
        label {
          margin: 0 10px 10px 0;
        }
      }


      &.-experience {
        label {
          width: 100%;
          display: inline-block;
          margin: 0 0 5px;
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    position: relative;
    display: none;
    margin-bottom: 30px;
    &.-show {
      display: flex;
      top: 0;
      height: auto;
    }
    &__content--action {
      margin: 15px 0 20px;
    }
  }

  @include media-breakpoint-down(xs) {
    &__content--option {
      max-width: 100%;
    }
  }

}
