@import 'styles/_variables';
@import 'styles/_mixins';

.a-section {
  width: 100%;
  padding: 130px 30px;
  display: flex;
  flex-direction: column;
  @include media-breakpoint-down(lg) {
    padding: 100px 30px;
  }
  @include media-breakpoint-down(sm) {
    padding: 60px 15px;
  }
}

.p-homePage {
  .a-section {
    padding: 80px 30px;
  }
}
