@import 'styles/_variables';
@import 'styles/_mixins';

.o-stripeCheckoutForm {
  -webkit-background-clip: padding-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow-y: auto;

  &__content {
    margin: auto;
    background: #fff;
    width: 100%;
    max-width: 500px;
    border-radius: 9px;
    overflow: hidden;

    &--card {
      width: 100%;
      padding: 18.5px 14px;
      border-radius: 4px;
      border: 1px solid #ccc;

      iframe {
        width: 100%;
      }
    }
  }

  &__offer {
    width: 100%;

    &--placeholder {
      max-width: 600px;
      width: 100%;
      height: 400px;
      background: #dbdbdb;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    header {
      background-color: #e6e6e6;
      padding: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      b {
        font-weight: bold !important;
        font-size: 20px !important;
      }

    }

    main {
      padding: 15px;

      p {
        padding-bottom: 15px;
        margin-bottom: 15px;
        font-weight: 600;
        font-size: 13px;
      }

      ul {
        margin-top: 15px;
        border: 1px solid #E4EAF5;
        border-radius: 10px;
        overflow: hidden;

        li {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          padding: 8px 16px;
          font-size: 13px;

          color: #3A5789;

          &:not(:last-child) {
            border-bottom: 1px solid #E4EAF5;
          }
          b, span{
            font-weight: 500;
          }
        }
      }
    }
  }

  &__form {
    position: relative;

    &Loader {
      z-index: 999;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      background: rgba(255, 255, 255, 0.8);
    }

    &Error {
      width: calc(100% - 40px);
      background: rgba(255, 0, 0, 0.2);
      padding: 10px 20px;
      border-radius: 6px;
      font-size: 14px;
      margin: 0 20px 20px;
    }

    &Group {
      padding: 20px;
    }

    header {
      -webkit-backface-visibility: hidden;
      -webkit-transform: translate3d(0,0,0);
      outline:none;
      border:none;
      text-decoration:none;
      background-color: #26395A;
      padding: 8px 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      b {
        font-weight: 500;
        font-size: 19px;
        color: #fff;
      }

      svg {
        path {
          fill: #fff
        }
      }

      span {
        font-weight: 500;
        font-size: 12px;
        color: #fff;
        margin-right: auto;

        &.-badge {
          margin-right: 10px;
          background: #F2C94C;
          border-radius: 10px;
          color: #050E30;
          padding: 0 10px;
        }
      }
    }
  }

  &__fields {
    display: flex;
    justify-content: space-between;

    &--exp,
    &--cvc {
      margin-left: 10px;
    }

    &--email,
    &--phone {
      width: 100%;
      max-width: calc(50% - 5px);
    }

    &--number {
      width: 100%;
      max-width: 100%;
      margin-left: 0;
    }
  }

  .a-modal__actions{
    margin: 8px 0 24px !important;
  }

}
