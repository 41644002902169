@import 'styles/_variables';
@import 'styles/_mixins';

.p-notifications {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 100;
  display: flex;
  justify-content: flex-end;
  overflow: hidden;

  &__closeTrigger {
    background: rgba(38, 57, 90, 0.4);
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    transition: all 0.2s linear;
    opacity: 0;
  }

  &__container {
    z-index: 2;
    position: relative;
    display: flex;
    align-items: flex-start;
    @include media-breakpoint-down(md) {
      flex-direction: column;
    }
  }

  &__box {
    transition: all 0.2s linear;
    box-shadow: 0 4px 95px #000000;
    background-color: #fff;
    max-width: 680px;
    height: 100%;
    overflow: hidden;
    width: 100%;
    right: -130%;
    position: relative;

    @include media-breakpoint-down(md) {
      order: 2;
    }
    @include media-breakpoint-down(sm) {
      max-width: 100%;
    }
  }

  &__items {
    flex: 1;

    header {
      width: 100%;
      padding: 20px 25px;
      border-bottom: 1px solid #eee;
      line-height: 1;
      display: flex;
      align-items: center;

      .-title {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin-right: auto;

        strong {
          color: #050e30;
          font-size: 18px;
          font-weight: 500;
        }

        button {
          font-size: 13px;
          color: #999;
          margin-top: 5px;
          text-decoration: underline;
        }
      }

      .-icon {
        width: 30px;
        height: 30px;
        border: 1px solid #ebedf1;
        border-radius: 5px;
        padding: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 15px;

        svg {
          width: 100%;

          path {
            fill: $colorPrimary;
          }
        }
      }
    }

    &--noItems {
      width: 100%;
      padding: 85px 30px;
      text-align: center;
      font-size: 22px;
      color: #ccc;
    }
  }

  &.-show {
    .p-notifications__box {
      right: 0;
    }
    .p-notifications__closeTrigger {
      opacity: 1;
    }
  }
}
