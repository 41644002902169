@import 'styles/_variables';
@import 'styles/_mixins';

.a-caseCardHeader {
  position: relative;
  &__header {
    width: 100%;
    height: 160px;
    position: relative;
    overflow: hidden;
    background: url('../../../../images/cases/other.png') no-repeat center;
    background-size: cover;
    display: flex;
    align-items: flex-end;
    justify-content: center;


    &--link {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 10;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(5, 14, 48, 0.6);
      opacity: 0;
      transition: all .2s linear;
      a {
        font-weight: 500;
        font-size: 12px;
        background: #499fd7;
        border-radius: 40px;
        padding: 8px 16px;
        color: #fff;
        outline: none;
        transition: all 0.2s linear;
        &:hover{
          background: #26395A;
        }
      }
    }

    &--timeLeft {
      position: absolute;
      top: 0;
      right: 0;
      background: #eb5757;
      border-radius: 0 0 0 15px;
      font-weight: 500;
      font-size: 12px;
      color: #fff;
      padding: 10px 15px;
    }

    &:after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(180deg, rgba(24, 36, 71, 0) 0%, #212a46 100%);
      display: block;
      z-index: 1;
    }

    &--info {
      z-index: 2;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-bottom: 15px;

      span {
        color: #fff;
        font-size: 16px;
        padding-top: 10px;
      }
    }
  }

  &__main {
    padding: 15px;
    display: flex;
    align-items: center;
    &--info {
      padding-left: 15px;
      display: flex;
      flex-direction: column;
      line-height: 1;
      strong {
        color: #499fd7;
        font-weight: 500;
        font-size: 16px;
        padding-bottom: 5px;
      }
      span {
        color: #fff;
        font-size: 13px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        svg {
          margin-left: -2px;
          margin-right: 5px;
        }
      }
    }
  }

  &.-businessEmployment .a-caseCardHeader__header {
    background: url('../../../../images/cases/business-employment.png')
      no-repeat center;
    background-size: cover;
  }

  &.-criminalLaw .a-caseCardHeader__header {
    background: url('../../../../images/cases/criminal-law.png') no-repeat
      center;
    background-size: cover;
  }

  &.-family .a-caseCardHeader__header {
    background: url('../../../../images/cases/family.png') no-repeat center;
    background-size: cover;
  }

  &.-noWinNoFee .a-caseCardHeader__header {
    background: url('../../../../images/cases/no-win-no-fee.png') no-repeat
      center;
    background-size: cover;
  }

  &.-property .a-caseCardHeader__header {
    background: url('../../../../images/cases/property.png') no-repeat center;
    background-size: cover;
  }

  &.-visaImmigration .a-caseCardHeader__header {
    background: url('../../../../images/cases/visa-immigration.png') no-repeat
      center;
    background-size: cover;
  }

  &.-willsProbate .a-caseCardHeader__header {
    background: url('../../../../images/cases/wills-probate.png') no-repeat
      center;
    background-size: cover;
  }

  &:hover{
    .a-caseCardHeader__header--link{
      opacity: 1;
    }
  }

}
