.p-termsAndPrivacy {
  width: 100%;
  background-color: #eee;
  margin-top: -30px;
  padding: 50px;

  .container {
    margin: auto;
    max-width: 1024px;
    .content {
      background: #fff;
      border-radius: 10px;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
      font-size: 14px;
      padding: 50px;
      .gray{
        color: #999;
      }

      b, span {
        font-weight: 700;
      }
      > b{
        padding-bottom: 20px;
        display: block;
        &.-subtitle{
          padding-top: 20px;
        }
      }
      ul,
      ol {
        list-style-type: disc;
        display: block;
        margin: 1em 0;
        padding-left: 40px;
        li {
          display: list-item;
        }
      }
      ol {
        list-style-type: decimal;
      }
      i {
        font-style: italic;
      }

      p{
        width: 100%;
        margin-bottom: 30px;
        b{
          width: 100%;
          display: block;
          &.center{
            text-align: center;
          }
        }
      }
      ul, ol{
        li{
          margin-bottom: 30px;
          ul{
            li{
              margin-bottom: 0;
            }
          }
          ol{
            li{
              margin-bottom: 15px;
            }
          }
        }
      }
      ul.-smallSpace{
        margin-top: -20px;
        margin-bottom: 30px;
        li{
          margin-bottom: 15px;
        }
      }
      ol {
        counter-reset: item;
      }
      ol > li {
        counter-increment: item;
      }
      ol ol > li {
        display: block;
      }

      ol ol > li:before {
        content: counters(item, ".") ". ";
        margin-left: -20px;
      }
    }
  }
}
