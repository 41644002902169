@import 'styles/_variables';
@import 'styles/_mixins';

.m-fixedFee {
  width: 100%;

  &__box {
    display: flex;
    padding: 16px 0;
    border-radius: 6px;
    align-items: center;

    &--icon {
      background: #499FD7;
      width: 34px;
      flex-shrink: 0;
      height: 34px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        color: #fff;
      }
    }

    span {
      margin-left: 16px;
      font-weight: 500;
      font-size: 15px;
      color: #26395A;
    }

    button {
      margin-left: auto;
      outline: none;

      svg {
        width: 22px;
        height: 22px;
      }
    }
  }

  &__form {
    display: flex;

    > span {
      font-size: 14px;
      margin-right: 8px;
      white-space: nowrap;
    }

    > label {
      display: inline-flex;
      align-items: center;
      justify-content: center;

      span {
        margin-left: 8px;
      }
    }
  }

}
