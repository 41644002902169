@import 'styles/_variables';
@import 'styles/_mixins';

.results_map {
  width: 100%;
  max-height: 100%;
  z-index: 1;

  .customMarker {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90px;
    height: 90px;
    border: 3px solid #fff;
    border-radius: 100%;
    margin: -45px 0 0 -45px;
    background-size: cover;
    background-color: #bbb;
    transition: all .2s ease-in-out;
    cursor: pointer;
    &.-online {
      border-color: #2bbd19 !important;

      &:before {
        content: '';
        width: 15px;
        height: 15px;
        background-color: #2bbd19;
        display: block;
        position: absolute;
        top: 5px;
        right: 2px;
        border: 2px solid #fff;
        border-radius: 100%;
      }
    }
    &:hover{
      transform: scale(1.2);
    }
  }
}