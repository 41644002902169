@import 'styles/_variables';
@import 'styles/_mixins';

.a-caseFilters {
  margin-left: auto;

  &__buttons {
    display: flex;
    align-items: center;
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  button {
    font-weight: 500;
    font-size: 12px;
    background: #26395A;
    border-radius: 40px;
    padding: 8px 16px;
    margin-left: 16px;
    color: #fff;
    outline: none;
    transition: all .2s linear;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover,
    &.-current {
      background: #499FD7;
    }
  }

  &__select {
    display: none;
    @include media-breakpoint-down(md) {
      display: flex;
    }

    button {
      background: #499FD7;
      font-weight: 500;
      font-size: 12px;
      min-width: 130px;
      justify-content: space-between;
      svg {
        width: 20px;
        height: 20px;
        margin-left: 5px;
        color: rgba(0, 0, 0, 0.54);
      }
    }
  }
}
