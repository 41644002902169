@import 'styles/_variables';
@import 'styles/_mixins';

.m-removeAccount {
  main {
    padding: 20px;
    display: flex;
    align-items: center;
  }
  &__text{
    strong{
      width: 100%;
      display: block;
      color: #050e30;
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 15px;
    }
    p{
      color: #53586e;
      font-size: 14px;
      font-weight: 400;
    }
  }
  &__action{
    margin-left: auto;
    padding-left: 30px;
  }
}