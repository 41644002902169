@import 'styles/_variables';
@import 'styles/_mixins';

.t-mainHeader {
  position: absolute;
  z-index: 3;
  width: 100%;
  padding: 50px 30px 0;

  &.-login {
    display: none;
  }

  &__logo {
    max-width: 160px;
    @include media-breakpoint-down(lg) {
      max-width: 140px;
    }
    @include media-breakpoint-down(sm) {
      margin-right: auto;
    }

    img {
      width: 100%;
    }
  }

  &__newOffer {
    border: 2px solid $colorPrimary;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #4f4f4f;
    border-radius: 40px;
    padding: 8px 20px;
    line-height: 1;
    margin-left: 30px;
    transition: all .2s linear;
    @include media-breakpoint-down(lg) {
      padding: 8px 15px;
      margin-left: 15px;
    }

    @include media-breakpoint-down(sm) {
      display: none;
    }

    svg {
      margin-left: 5px;

      path {
        fill: $colorPrimary;
        transition: all .2s linear;
      }
    }

    &:hover {
      color: #fff;
      background: $colorPrimary;

      svg {
        path {
          fill: #fff
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    padding: 15px;
    &.-login {
      display: block;
    }
  }

  &__toggleNav {
    display: none;
    @include media-breakpoint-down(sm) {
      display: flex;
      background: transparent;
      width: 30px;
      border-radius: 5px;
      align-items: center;
      justify-content: center;
      z-index: 999;
      outline: none !important;
      span {
        position: relative;
        width: 22px;
        height: 4px;
        background: $colorPrimary;
        border-radius: 1px;
        -webkit-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;

        &:before,
        &:after {
          content: '';
          display: inline-block;

          width: 22px;
          height: 4px;
          background: $colorPrimary;
          border-radius: 1px;
          position: absolute;
          top: -8px;
          left: 0;
          -webkit-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;
        }

        &:after {
          top: 8px;
        }
      }
      &.-close {
        background: transparent;

        span {
          background: transparent;

          &:before {
            transform: rotate(45deg) translate(5px, 6px);
          }

          &:after {
            transform: rotate(-45deg) translate(5px, -6px);
          }
        }
      }
    }
  }

  &__nav {
    @include media-breakpoint-down(sm) {
      display: none;

      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      flex-direction: column;
      background: rgba(0, 0, 0, 0.8);
      z-index: 998;
      align-items: center;
      justify-content: center;
      &.-show {
        display: flex;
      }
    }

    a {
      cursor: pointer;
      color: #fff;
      font-size: 20px;
      font-weight: 700;
      line-height: 30px;
      text-transform: uppercase;
      margin-left: 60px;
      transition: all 0.2s linear;
      @include media-breakpoint-down(lg) {
        font-size: 18px;
        margin-left: 40px;
      }
      @include media-breakpoint-down(md) {
        margin-left: 25px;
      }

      @include media-breakpoint-down(sm) {
        font-size: 32px;
        line-height: 1;
        margin-bottom: 30px;
        position: relative;
      }

      &:hover {
        color: #18a1db;
      }

      &.-join {
        color: $colorPrimary;
        background: transparent;
        border: 2px solid $colorPrimary;
        padding: 15px 50px;
        font-weight: 500;
        font-size: 18px;
        @include media-breakpoint-down(lg) {
          font-size: 16px;
          padding: 15px 40px;
        }
        @include media-breakpoint-down(md) {
          padding: 12px 20px;
        }

        &:hover {
          background: $colorPrimary;
          color: #fff;
        }
      }
    }
  }

  &__dropdown {
    position: relative;

    &--trigger {
      cursor: pointer;
      border-radius: 100%;

      &:before {
        content: '';
        width: 14px;
        height: 14px;
        border-radius: 100%;
        position: absolute;
        top: 0;
        right: -2px;
        background-color: #00BA88;
        border: 2px solid #FCFCFC;
      }
    }

    &--box {
      background: #fff;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
      min-width: 220px;
      display: flex;
      flex-direction: column;
      border-radius: 5px;
      align-items: center;
      position: relative;
      z-index: 2;
      overflow: hidden;
    }

    &--header {
      background: #fff;
      padding: 30px 15px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      span {
        margin-top: 10px;
        font-weight: bold;
        color: #333;
        width: 100%;
        text-align: center;
      }

      a {
        color: #666;

        small {
          margin: 0 5px;
          font-size: 12px;
        }

        svg {
          width: 15px;
        }
      }
    }

    &--avatar {
      border-radius: 100%;

      span {
        font-weight: normal;
        color: #fff;
      }
    }

    &--content {
      position: absolute;
      top: 100%;
      right: -4px;
      margin-top: 10px;
      display: none;

      &.-open {
        display: block;
        @include media-breakpoint-down(sm) {
          display: none;
        }
      }

      &:before,
      &:after {
        content: '';
        border-bottom: 6px solid #fff;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        position: absolute;
        bottom: 100%;
        right: 15px;
        z-index: 3;
      }

      &:after {
        border-bottom-color: #ccc;
        margin-bottom: 2px;
        z-index: 1;
        filter: blur(2px);
      }

      button {
        background: $colorPrimary;
        color: #fff;
        padding: 15px;
        width: 100%;

        span {
          margin-right: 10px;
        }
      }
    }
  }

  &.-relative {
    position: relative;
    box-shadow: 0 8px 23px rgba(185, 185, 185, 0.19);
    background-color: #fff;
    margin-bottom: 30px;
    padding: 30px;
    z-index: 99;

    .t-mainHeader__nav {
      a {
        color: #bebebe;

        &.-current,
        &:hover {
          color: $colorPrimary;
        }

        &.-join {
          border: 1px solid $colorPrimary;
          color: $colorPrimary;
          @include media-breakpoint-down(sm) {
            color: #fff;
            background: $colorPrimary;
            font-size: 18px;
          }

          &:hover {
            color: #fff;
          }
        }
      }
    }
  }

  &.-panel {
    z-index: 20;
    position: relative;
    border-bottom: 1px solid #f4f4f4;
    padding: 0 30px;
    font-family: $defaultPanelFontFamily;

    @include media-breakpoint-up(md) {
      .t-mainHeader__nav {
        margin-left: auto;
        margin-right: 30px;
        height: 100%;
        padding: 15px 0;

        &--link {
          cursor: pointer;
          color: #747474;
          font-size: 15px;
          font-weight: 400;
          line-height: 1;
          display: inline-flex;
          text-transform: none;
          align-items: center;
          justify-content: center;
          background: transparent;
          padding: 15px 30px;
          border-radius: 40px;
          margin: 0;
          transition: color .2s linear;

          svg {
            margin-left: 5px;

            path {
              transition: all .2s linear;
              fill: #747474;
            }
          }

          &:hover {
            color: $colorPrimary;

            svg {
              path {
                fill: $colorPrimary;
              }
            }
          }

          &.-current {
            color: #fff;
            font-weight: 400;
            background: $colorPrimary;

            svg {
              path {
                fill: #fff;
              }
            }
          }

          &-notification {
            width: 18px;
            height: 18px;
            background: #EB5757;
            border-radius: 100%;
            margin: -15px 0 0 -8px;
            font-size: 10px;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            font-style: normal;
            font-weight: 600;
          }
        }
      }
    }

    @include media-breakpoint-down(lg) {
      .t-mainHeader__nav {
        &--link {
          padding: 10px 20px;
        }
      }
    }

    @include media-breakpoint-down(md) {
      .t-mainHeader__nav {
        &--link {
          span {
            display: none;
          }

          svg {
            height: 20px;
            margin: 0;
          }
        }
      }

      &__nav {
        margin-left: auto;
        margin-right: 30px;
        height: 100%;
        padding: 15px 0;

        &--link {
          padding: 8px 10px;

          svg {
            width: 15px;
          }
        }
      }
    }
    @include media-breakpoint-down(sm) {
      padding: 15px 20px;
      margin-bottom: 0;

      .t-mainHeader__dropdown--trigger {
        width: 48px;
        margin-right: 20px;
        margin-left: auto;
        position: relative;
      }
    }
  }

  .-show {
    @include media-breakpoint-down(sm) {
      .t-mainHeader__nav--link-notification {
        width: 18px;
        height: 18px;
        border-radius: 100%;
        margin: -5px 0 0;
        position: absolute;
        background-color: #EB5757;
        font-size: 10px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-style: normal;
        font-weight: 600;
        top: -7px;
        right: -15px;
      }
    }
  }

  &__subHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 20px;

    @include media-breakpoint-up(md) {
      display: none;
    }

    &--newOffer {
      border: 2px solid $colorPrimary;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #4f4f4f;
      border-radius: 40px;
      padding: 5px 15px;
      line-height: 1;
      transition: all .2s linear;

      svg {
        margin-left: 5px;

        path {
          fill: $colorPrimary;
          transition: all .2s linear;
        }
      }

      &:hover {
        color: #fff;
        background: $colorPrimary;

        svg {
          path {
            fill: #fff
          }
        }
      }
    }

    &--link {
      cursor: pointer;
      color: #747474;
      font-size: 14px;
      font-weight: 400;
      line-height: 1;
      display: inline-flex;
      text-transform: none;
      align-items: center;
      justify-content: center;
      background: transparent;
      margin: 0;
      transition: color .2s linear;

      svg {
        margin-left: 5px;
        max-height: 20px;

        path {
          transition: all .2s linear;
          fill: #747474;
        }
      }

      &:hover {
        color: $colorPrimary;

        svg {
          path {
            fill: $colorPrimary;
          }
        }
      }

      &.-current {
        color: #fff;
        font-weight: 400;
        background: $colorPrimary;

        svg {
          path {
            fill: #fff;
          }
        }
      }

      &-notification {
        width: 18px;
        height: 18px;
        background: #EB5757;
        border-radius: 100%;
        margin: -15px 0 0 -8px;
        font-size: 10px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-style: normal;
        font-weight: 600;
      }
    }
  }

  &__menuModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(5, 14, 48, 0.8);
    z-index: 1000;
    overflow-y: auto;

    &--close {
      position: absolute;
      top: 15px;
      right: 15px;
    }

    .t-mobileMenu {
      &__content {
        display: flex;
        flex-direction: column;
        padding: 50px 15px;
      }

      &__header {
        display: flex;
        align-items: center;
        justify-content: center;

        &--avatar {
          margin-right: 15px;
          border-radius: 100%;
          border: 3px solid #00ba88;
          padding: 7px;
          position: relative;

          .-indicator {
            position: absolute;
            top: 15px;
            right: 7px;
            width: 17px;
            height: 17px;
            border-radius: 100%;
            background: #00ba88;
            border: 3px solid #26395a;
            display: flex;
            box-shadow: 0 0 7px rgba(255, 255, 255, 0.3);
          }
        }

        &--info {
          padding-left: 15px;
          max-width: 50%;
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        &--name {
          font-weight: 500;
          font-size: 22px;
          text-transform: uppercase;
          margin: 15px 0 5px;
          line-height: 1;
          color: #fff;
          text-align: center;
        }

        &--viewProfile {
          margin-top: 20px;
          margin-bottom: 20px;

          a {
            background: #26395A;
            border-radius: 40px;
            font-weight: 500;
            font-size: 12px;
            padding: 10px 20px;
            transition: all .2s linear;
            color: #fff;

            &:hover {
              background: #395280;
            }
          }
        }
      }

      &__subHeader {
        display: flex;
        padding: 15px 0;
        justify-content: space-between;

        &--notifications {
          width: 100%;
          overflow: hidden;

          &.-half {
            max-width: calc(50% - 7.5px);
          }

          button {
            width: 100%;
            background: #3A5789;
            border-radius: 10px;
            min-height: 140px;
            color: #fff;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            svg {
              path {
                fill: #fff
              }
            }

            span {
              font-size: 15px;
            }

            p {
              padding-top: 15px;
              font-size: 13px;
            }

            .-icon {
              position: relative;

              i {
                width: 18px;
                height: 18px;
                border-radius: 100%;
                right: -10px;
                position: absolute;
                background-color: #EB5757;
                font-size: 10px;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                font-style: normal;
                font-weight: 600;
                top: -7px;
              }
            }
          }
        }

        &--map {
          width: 100%;
          max-width: calc(50% - 7.5px);
          border-radius: 10px;
          overflow: hidden;

          a[href^="http://maps.google.com/maps"], a[href^="https://maps.google.com/maps"] {
            display: none !important;
          }

          .gmnoprint a, .gmnoprint span, .gm-style-cc {
            display: none;
          }

          &-pin {
            position: absolute;
            transform: translate(-50%, -50%);
          }
        }
      }

      &__menu {
        background: #fff;
        border-radius: 10px;

        nav {
          a {
            width: 100%;
            display: flex;
            align-items: center;
            padding: 20px;

            &:not(:last-child) {
              border-bottom: 1px solid rgba(5, 14, 48, 0.14);
            }

            .-icon {
              position: relative;
              border: 1px solid #EBEDF1;
              border-radius: 5px;
              width: 40px;
              height: 40px;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 7px;

              svg {
                max-width: 100%;

                path {
                  fill: $colorPrimary
                }
              }

              i {
                width: 18px;
                height: 18px;
                border-radius: 100%;
                right: -10px;
                position: absolute;
                background-color: #EB5757;
                font-size: 10px;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                font-style: normal;
                font-weight: 600;
                top: -7px;
              }

              &.-big {
                svg {
                  width: 30px;
                  height: 30px;
                }
              }

            }

            span {
              font-size: 16px;
              font-weight: 500;
              color: #050E30;
              padding-left: 15px;
            }
          }
        }
      }

      &__logout {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;

        button {
          color: $colorPrimary;

          svg {
            margin-left: 5px;
          }
        }
      }
    }
  }

  &__notificationsModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    overflow-y: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    pointer-events: none;

    &.-show {
      pointer-events: auto;
    }
  }
}

.t-teamInvites {
  width: 100%;
  color: #fff;
  display: flex;
  flex-direction: column;

  &__item {
    background: $colorPrimary;
    padding: 10px 30px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);

    a {
      text-decoration: underline;

      &:hover {
        color: #024663;
      }
    }

    &--actions {
      border-left: 1px solid #057db1;
      padding-left: 30px;
      margin-left: 30px;
    }

    button {
      width: auto;
      color: #fff;
      border-radius: 30px;
      padding: 5px 20px;
      background: #057db1;
      white-space: nowrap;
      font-size: 14px;

      &.-decline {
        margin-right: 15px;
      }

      &:hover {
        background: #045f87;
      }
    }
  }
}
