@import "styles/_variables";
@import "styles/_mixins";

.a-timelineItem {
  display: flex;
  align-items: center;
  padding: 24px 0;
  width: 100%;
  position: relative;

  &__bullet {
    width: 12px;
    height: 12px;
    flex-shrink: 0;
    border: 2px solid #499FD7;
    background: #fff;
    border-radius: 100%;
    display: flex;
    margin-right: 16px;
    margin-left: -5px;
    z-index: 2;

    @include media-breakpoint-down(xs){
      margin-right: 8px;
    }
  }

  &:first-child {
    .a-timelineItem__bullet {
      background: #499FD7;
    }
  }

  &__icon {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    border: 2px solid #EDEFF2;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    padding: 8px;


    svg {
      width: 100%;
      height: 100%;

      path {
        fill: #499FD7
      }
    }

    &.-started,
    &.-completed {
      padding: 6px;

      svg {
        path {
          fill: #0AB145
        }
      }
    }

    &.-dispute {
      svg {
        path {
          fill: #b1870a
        }
      }
    }

    &.-review {
      padding: 6px;

      svg {
        path {
          fill: #FFB800
        }
      }
    }

    @include media-breakpoint-down(xs) {
      width: 30px;
      height: 30px;
      margin-right: 8px;
      padding: 4px !important;
    }
  }

  &__info {
    display: flex;
    width: 100%;

    &--text {
      display: flex;
      flex-direction: column;
      margin-right: 32px;
      @include media-breakpoint-down(xs){
        margin-right: 0;
      }
      small {
        font-size: 12px;
        color: #3A5789;
        padding-bottom: 8px;
      }

      p {
        font-size: 15px;
        color: #26395A;
        line-height: 1.2;
      }
    }

    &--time {
      margin-left: auto;
      height: 25px;
      font-size: 11px;
      color: #fff;
      background: #3A5789;
      border-radius: 100px;
      padding: 4px 8px;
      white-space: nowrap;
      display: flex;
      align-items: center;
      justify-content: center;
      @include media-breakpoint-down(md) {
        position: absolute;
        top: 20px;
        right: 0;
        font-size: 10px;
      }
    }
  }
}
