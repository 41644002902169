@import "styles/_variables";
@import "styles/_mixins";

.o-chatForm {
  width: 100%;
  display: flex;
  background-color: #499FD7;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  line-height: 1;

  &__field {
    width: 100%;
    padding: 2px 15px 0;
    textarea {
      border-radius: 20px;
      background-color: #fff;
      font-weight: 400;
      width: 100%;
      padding: 10px 16px;
      outline: none;
      overflow: auto;
      resize: none;
      height: 40px;
      line-height: 1.4;
      font-size: 14px;
      color: #6F8DC1;

    }
  }
  &__submit{
    height: 21px;
  }
}
