@import 'styles/_variables';
@import 'styles/_mixins';

.a-whiteBox {
  width: 100%;
  max-width: calc(100% - 30px);
  background: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin: 15px;

  &__header {
    padding: 15px;
    width: 100%;
    border-bottom: 1px solid rgba(38, 57, 90, .2);
    display: flex;
    align-items: center;

    &--icon {
      width: 30px;
      height: 30px;
      border-radius: 5px;
      border: 1px solid rgba(38, 57, 90, .2);
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;

      svg {
        width: 100%;
      }
    }

    span {
      padding-left: 10px;
      color: #26395A;
      font-weight: 500;
      font-size: 18px;
    }
    &--actions{
      margin-left: auto;
    }
  }

  &__main {
    padding: 15px;
    color: #26395A;
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    position: relative;
    min-height: 200px;
    .owl-dots{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 24px 0 8px;
      .owl-dot{
        width: 14px;
        height: 14px;
        border-radius: 100%;
        border: 2px solid #fff;
        margin: 0 4px;
        &.active{
          background: #fff;
        }
      }
    }
  }

  &.-withOpacity {
    background: rgba(255, 255, 255, .2);

    .a-whiteBox__header {
      border-bottom: 1px solid rgba(255, 255, 255, .3);

      span {
        color: #fff;
      }
    }
  }
}
