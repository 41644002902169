@import 'styles/_variables';
@import 'styles/_mixins';

.p-lawyerRegistration {
  max-width: 600px;
  margin: auto;

  &__stepper {
    box-shadow: 0 0 29px rgba(0, 0, 0, 0.16);
    background-color: #fff;
    margin-bottom: 30px;
    border-radius: 5px;
  }

  &__content {
    box-shadow: 0 0 29px rgba(0, 0, 0, 0.16);
    background-color: #11122d;
    border-radius: 5px;
    padding: 0 30px 30px;
    margin-bottom: 50px;

    &--title {
      width: 100%;
      text-align: center;
      opacity: 0.95;
      color: #fff;
      font-size: 30px;
      font-weight: 400;
      padding: 40px 0;

      b {
        font-weight: 400;
        color: $colorPrimary;
      }
    }

    &--page {
      @include media-breakpoint-up(md) {
        .-half {
          display: flex;
          justify-content: space-between;
          > div {
            max-width: calc(50% - 15px);
          }
        }
      }
    }

    &--actions {
      width: 100%;
      display: flex;
      margin-top: 40px;

      button {
        flex: 1;
        color: #fff;
        font-size: 14px;
        font-weight: 500;
        padding: 15px 30px;
        border-radius: 5px;
        transition: all 0.2s linear;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          opacity: 0.8;
        }
      }

      &-back {
        margin-right: 10px;
        background-color: #363753;
      }

      &-forward {
        margin-left: 10px;
        background: $colorPrimary;
      }
    }
  }
}
