@import 'styles/_variables';
@import 'styles/_mixins';

.a-caseCardContent {
  display: flex;
  flex-direction: column;
  flex: 1;

  p {
    padding: 0 20px 0;
    color: #fff;
    font-weight: 500;
    font-size: 13px;
    height: 80px;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 15px;
    padding-bottom: 50px;
    margin-top: auto;
    button{
      margin: 0 15px;
      svg{
        width: 20px;
        height: 20px;
        path{
          fill: #fff
        }
      }
      &:disabled{
        opacity: .2;
        cursor: default;
      }
    }
  }
  &__footer{
    border-top: 1px solid #26395A;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &--status{
      font-weight: 600;
      font-size: 13px;
      background: #EB5757;
      border-radius: 40px;
      padding: 0 20px;
      color: #fff;
      height: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: -17px;
      &.-active{
        background: #00BA88;
      }
      &.-offer{
        background: #F2C94C;
      }
      &.-offer{
        background: #F2C94C;
      }
    }
    &--total{
      color: #fff;
      font-weight: 500;
      font-size: 20px;
      padding: 15px 15px 20px;
      span{
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
