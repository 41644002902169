@import 'styles/_variables';
@import 'styles/_mixins';

.o-clientDashboard {
  color: #fff;
  position: relative;
  z-index: 2;

  &__searchService{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    svg{
      width: 68px;
      height: 53px;
      color: #499FD7;
    }
    h3{
      margin: 10px 0;
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      color: #499FD7;
      font-size: 22px;
      line-height: 1;
      font-weight: 500;
      span{
        font-size: 17px;
      }
    }
    strong{
      font-weight: 500;
      font-size: 15px;
      color: #26395A;
      margin-bottom: 15px;
    }
    a{
      background: #0097B8;
      color: #fff;
      font-weight: 500;
      font-size: 16px;
      padding: 12px 30px;
      border-radius: 40px;
      transition: all .2s linear;
      &:hover{
        background: #03667c;
      }
    }
  }

  &__container {
    max-width: 1200px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 50px 0;
    @include media-breakpoint-down(sm) {
      flex-direction: column;
      padding: 30px 0;
    }
  }

  &__main {
    width: 100%;
    display: flex;
    padding-right: 15px;
    flex-direction: column;
    max-width: calc(100% - 300px);
    @include media-breakpoint-down(sm){
      max-width: 100%;
      padding-right: 0;
    }

    &--box {
      width: 100%;
      border-radius: 10px;
      background: rgba(5, 14, 48, 0.6);
      padding: 30px;
      margin-bottom: 25px;
      display: flex;
      flex-direction: column;

      &.-welcome {
        header {
          display: flex;
          justify-content: space-between;
          margin-bottom: 25px;
          line-height: 1;

          h3 {
            font-size: 18px;
          }

          svg {
            path {
              fill: #fff
            }
          }
        }
      }

      main {
        &.-complete {
          font-size: 14px;
        }
      }
    }

    &--boxes {
      display: flex;
      @include media-breakpoint-down(md) {
        flex-direction: column
      }
    }
  }

  &__accountActivation {
    .content {
      header {
        margin-bottom: 30px;

        h2 {
          font-size: 25px;
        }

        p {
          font-size: 18px;
        }
      }

      .box {

        border: 2px solid #00BA88;
        border-radius: 15px;
        padding: 10px 20px;

        ul {

          li {
            display: flex;
            align-items: center;
            margin: 10px 0;
            width: 100%;
            @include media-breakpoint-down(xs) {
              align-items: flex-start;
              flex-wrap: wrap;
            }

            svg {
              margin-right: 10px;
              width: 20px;
              height: 20px;

              path {
                fill: #fff
              }
            }

            p {
              font-size: 15px;
            }

            a, button {
              margin-left: auto;
              border: 2px solid #fff;
              box-sizing: border-box;
              border-radius: 40px;
              padding: 10px 15px;
              line-height: 1;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 14px;

              @include media-breakpoint-down(xs) {
                width: 100%;
                margin: 5px 0 15px;
              }

              svg {
                margin: 0 0 0 5px;
              }
            }

            &.-disabled {
              opacity: .3;

              button {
                cursor: not-allowed;
              }
            }
          }
        }

        .statusBar {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          .bar {
            width: 100%;
            height: 14px;
            background: #26395A;
            border-radius: 15px;
            overflow: hidden;

            span {
              border-radius: 15px;
              width: 0;
              height: 14px;
              display: block;
              background: rgb(0, 129, 234);
              background: linear-gradient(90deg, rgba(0, 129, 234, 1) 0%, rgba(127, 255, 224, 1) 100%, rgba(0, 212, 255, 1) 100%);
            }
          }

          .percentage {
            font-size: 20px;
            margin-left: 10px;
          }
        }
      }

      footer {
        line-height: 1;
        margin-top: 5px;

        small {
          font-size: 10px;
          color: #ccc;
        }
      }
    }
  }

  &__casesSummary {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin: auto;
    width: 100%;

    &--item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      line-height: 1;
      padding: 15px;
      @include media-breakpoint-down(lg){
        font-size: 14px;
        padding: 10px;
      }
      @include media-breakpoint-down(md){
        font-size: 16px;
        padding: 15px;
      }

      @include media-breakpoint-down(xs){
        font-size: 12px;
        padding: 10px;
      }

      strong {
        font-size: 50px;
        font-weight: 400;
        line-height: 1;
      }

      svg {
        width: 42px;
        margin-bottom: 10px;
      }

      &.-completed {
        span {
          color: #008964;
        }
      }

      &.-inProgress {
        span {
          color: #E69800;
        }
      }
    }
  }

  &__totalEarnings {
    display: flex;
    flex-direction: column;
    width: 100%;

    &--item {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 0;
      color: #050E30;

      strong {
        font-size: 14px;
        font-weight: 500;
      }

      span {
        font-weight: 500;
        font-size: 17px;
      }

      &:not(:last-child) {
        border-bottom: 1px dashed #E9EBEF;
      }

      &:last-child {
        padding-bottom: 0;
      }

      &.-received {
        color: #008964;
      }

      &.-pending {
        color: #E79800;
      }
    }
  }

  &__cases {
    &--icon {
      path {
        fill: #499FD7
      }
    }

    .a-whiteBox__main {
      justify-content: flex-start;
      align-items: unset;
      padding: 15px 0;

      .owl-carousel .owl-item {
        position: unset !important;
      }

      .m-caseCard {
        height: unset;
      }
    }

    &--carousel {
      width: 100%;
      display: flex;
      position: relative;

    }
  }
}
