@import 'styles/_variables';
@import 'styles/_mixins';

.o-videoCall {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1200;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  &__content {
    background: #fff;
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }
}
