@import 'styles/_variables';
@import 'styles/_mixins';

.a-panelContainer {
  overflow: hidden;
  max-width: 100%;
  position: relative;
  background: url('../../../images/bg-panel.png') no-repeat bottom center fixed;
  @include media-breakpoint-down(xl){
    min-height: calc(100vh - 94px);
  }
  @include media-breakpoint-down(sm){
    min-height: calc(100vh - 129px);
  }
}
