@import 'styles/_variables';
@import 'styles/_mixins';

.p-beFeatured {
  margin-top: -30px;

  &__header {
    width: 100%;
    background: url('../../../images/be-featured-map.jpg') no-repeat 0 0;
    background-size: cover;
    padding: 230px 0;
    text-align: center;

    h2 {
      color: #fff;
      font-size: 60px;
      font-weight: 600;

      @include media-breakpoint-down(sm) {
        font-size: 45px;
        line-height: 1.3;
      }
      @include media-breakpoint-down(xs) {
        font-size: 38px;
      }
    }

    p {
      color: #fff;
      font-size: 18px;
      font-weight: 400;
      max-width: 945px;
      margin: 30px auto 0;
    }
  }

  &__become {
    text-align: center;

    p {
      color: #888;
      font-size: 18px;
      font-weight: 500;
      line-height: 32px;
      max-width: 820px;
      margin: 30px auto;
    }

    button {
      padding: 20px 50px;
      color: #fff;
      font-size: 18px;
      font-weight: 600;
      line-height: 30px;
      text-transform: uppercase;

      letter-spacing: 1.08px;
    }

    &--video {
      position: relative;
      width: 100%;
      text-align: center;
      border-radius: 11px;
      overflow: hidden;
      max-width: 1120px;
      margin: 60px auto 0;
      cursor: pointer;
      min-height: 360px;

      &:after {
        content: '';
        width: 100%;
        height: 100%;
        display: inline-block;
        background-color: #11122d;
        opacity: 0.5;
        z-index: 2;
        position: absolute;
        top: 0;
        left: 0;
        transition: all .1s ease-in-out;
      }

      img {
        margin: auto;
        object-fit: cover;
        width: 100%;
        height: 100%;
        min-height: 360px;
      }

      &Button {
        position: absolute;
        background-color: #fff;
        width: 140px;
        height: 140px;
        border-radius: 100%;
        z-index: 3;
        top: 50%;
        left: 50%;
        @include translate(-50%, -50%);
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all .1s ease-in-out;

        @include media-breakpoint-down(md) {
          width: 100px;
          height: 100px;
        }
        @include media-breakpoint-down(sm) {
          width: 60px;
          height: 60px;
          svg{
            width: 25px;
            height: 25px;
            margin-left: 4px;
          }
        }

        &:after {
          content: '';
          width: 174px;
          height: 174px;
          display: inline-block;
          background: rgba(255, 255, 255, .35);
          position: absolute;
          top: 50%;
          left: 50%;
          @include translate(-50%, -50%);
          border-radius: 100%;
          transition: all .1s ease-in-out;
          @include media-breakpoint-down(md) {
            width: 134px;
            height: 134px;
          }
          @include media-breakpoint-down(sm) {
            width: 84px;
            height: 84px;
          }
        }
      }

      &:hover {
        &:after {
          opacity: 0.6;
        }

        .p-beFeatured__become--videoButton {
          &:after {
            width: 180px;
            height: 180px;
            @include media-breakpoint-down(md) {
              width: 140px;
              height: 140px;
            }
            @include media-breakpoint-down(sm) {
              width: 90px;
              height: 90px;
            }
          }
        }
      }
    }
  }

  &__info {
    background-color: #11122d;
    padding: 130px 100px;

    @include media-breakpoint-down(lg) {
      padding: 130px 30px;
    }

    &--row {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      @include media-breakpoint-down(md) {
        flex-direction: column;
        align-items: flex-start;
      }

      &.-right {
        justify-content: flex-end;
        margin-top: 50px;
        @include media-breakpoint-down(md) {
          margin-top: 100px;
          .p-beFeatured__info {
            &--text {
              order: 2;
            }

            &--image {
              order: 1;
            }
          }
        }
      }
    }

    &--image {
      img {
        max-width: 100%;
      }
    }

    &--text {
      margin-left: 80px;
      @include media-breakpoint-down(md) {
        margin: 50px 15px 0;
      }

      h2 {
        color: #fff;
        line-height: 1.3;
      }

      p {
        color: #fff;
        font-size: 18px;
        font-weight: 400;
        line-height: 30px;
        width: 100%;
        max-width: 530px;
        margin: 40px 0;
      }

      ul {
        margin-bottom: 40px;

        li {
          color: #fff;
          font-size: 18px;
          font-weight: 400;
          display: flex;
          align-items: center;
          margin-bottom: 15px;

          svg {
            max-width: 15px;
            max-height: 15px;
            margin-right: 10px;

            path {
              fill: $colorPrimary
            }
          }
        }
      }

      button {
        padding: 15px 50px;
        color: #fff;
        font-size: 18px;
        font-weight: 600;
        line-height: 30px;
        text-transform: uppercase;
        letter-spacing: 1.08px;
        min-width: 230px;

        @include media-breakpoint-down(md) {
          font-size: 14px;
        }
      }
    }
  }

  &__payments {
    text-align: center;

    &--items {
      display: flex;
      align-items: flex-start;
      margin-top: 60px;

      @include media-breakpoint-down(sm) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }

    &--item {
      width: 100%;
      max-width: 33.33%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @include media-breakpoint-down(sm) {
        margin-bottom: 50px;
        max-width: 100%;
      }

      &Icon {
        width: 60px;
        height: 60px;

        svg {
          max-width: 100%;
          max-height: 100%;

          path {
            fill: $colorPrimary
          }
        }
      }

      &Title {
        color: #222;
        font-size: 24px;
        font-weight: 600;
        line-height: 35px;
        margin: 30px 0;

        @include media-breakpoint-only(md) {
          font-size: 22px;
        }
      }

      &Bullet {
        width: 100%;
        height: 3px;
        background-color: #ddd;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 30px;

        @include media-breakpoint-down(sm) {
          background: transparent;
        }

        &:before {
          content: '';
          width: 30px;
          height: 30px;
          background: $colorPrimary;
          border: 7.5px solid #daf4ff;
          border-radius: 100%;
          display: inline-block;
        }
      }

      &Text {
        max-width: 300px;
        color: #888;
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;

        @include media-breakpoint-only(md) {
          font-size: 14px;
        }

        @include media-breakpoint-down(sm) {
          font-size: 16px;
        }
      }
    }
  }

  &__appStores {
    text-align: center;
    background-color: #f8f9ff;

    h2 {
      max-width: 564px;
      margin: auto;
      line-height: 1.2;
    }

    p {
      width: 100%;
      display: inline-block;
      padding: 40px 0 25px;
      color: #888;
      font-size: 20px;
      font-weight: 400;
      line-height: 35px;
      @include media-breakpoint-down(sm) {
        line-height: 1.2;
        font-size: 18px;
        padding: 15px 0;
      }
    }

    &--buttons {
      width: 100%;
      display: flex;
      justify-content: center;

      a {
        margin: 15px;
        transition: all .2s linear;

        @include media-breakpoint-down(sm) {
          margin: 0;
          &:not(:last-child) {
            margin-right: 15px;
          }
        }

        &:hover {
          opacity: .8;
        }
      }
    }
  }
}
