@import 'styles/_variables';
@import 'styles/_mixins';

.o-popularPosts {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 60px;
    align-items: center;
    flex-direction: column;
    h2 {
      color: #222222;
      font-size: 40px;
      font-weight: 600;
      @include media-breakpoint-down(sm) {
        font-size: 30px;
        text-align: center;
      }
    }
    p {
      color: #11122e;
      font-size: 24px;
      font-weight: 300;
      max-width: 660px;
      text-align: center;
      padding-top: 30px;
      @include media-breakpoint-down(sm) {
        font-size: 18px;
      }
    }
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    width: 100%;
    padding-bottom: 60px;

    @include media-breakpoint-down(md) {
      padding-bottom: 0;
      align-items: flex-start;
      height: auto;
    }
    &--image{
      display: none;
    }

    @include media-breakpoint-down(sm) {
      &--video{
        display: none;
      }
      &--image{
        display: block;
      }
    }

    &--item {
      min-width: calc(33.33% - 25px);
      margin: 0 30px 0 0;
      @include media-breakpoint-down(md) {
        min-width: 300px;
      }

      @include media-breakpoint-down(sm) {
        min-width: 260px;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .simplebar-track.simplebar-horizontal {
    background-color: #ececec;
    width: 100%;
    height: 10px !important;
    border-radius: 5px;
  }

  .simplebar-track {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  .simplebar-content-wrapper {
    overflow-y: hidden !important;
  }

  .simplebar-scrollbar {
    height: 10px !important;
    top: 0 !important;

    &:before {
      background: $colorPrimary;
      height: 10px !important;
      border-radius: 5px;
    }
  }
}
