@import 'styles/_variables';
@import 'styles/_mixins';


.p-resultsPage {
  width: 100%;

  &__container {
    padding: 0 30px;
  }
}
