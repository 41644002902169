@import 'styles/_variables';
@import 'styles/_mixins';

.m-notificationItem {
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #eee;
  padding: 20px;
  outline: none !important;
  transition: all 0.2s linear;

  &:hover {
    background: rgba(250, 250, 250, 0.7);
  }

  &.-unread {
    background: rgba(24, 161, 219, 0.03);
  }

  &__icon {
    width: 40px;
    flex-shrink: 0;
    height: 40px;
    border: 2px solid #EDEFF2;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;

    @include media-breakpoint-down(sm) {
      padding: 10px;
    }

    svg {
      width: 20px;
      height: 20px;

      path {
        fill: #333 !important;
      }
    }
  }

  &__content {
    color: #a2a2a2;
    font-size: 16px;
    font-weight: 500;
    width: 100%;
    display: flex;
    align-items: center;
    @include media-breakpoint-down(xs) {
      flex-direction: column;
      align-items: flex-start;
    }

    &--text {
      color: #26395A;
      font-weight: 500;
      text-align: left;
      margin-right: 30px;
      font-size: 15px;

      @include media-breakpoint-down(sm) {
        font-size: 14px;
      }
      @include media-breakpoint-down(xs) {
        margin: 0;
        order: 2;
      }
    }

    a {
      color: #424143;
      transition: all 0.2s linear;

      &:hover {
        color: $colorPrimary;
      }
    }

    &--date {
      flex-shrink: 0;
      margin-left: auto;
      color: #3A5789;
      font-size: 12px;
      font-weight: 400;

      @include media-breakpoint-down(xs) {
        margin: 0 0 10px;
        order: 1;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        font-size: 13px;
      }

      small {
        margin-right: 15px;
      }

      span {
        color: #fff;
        background: #26395A;
        padding: 5px 10px;
        border-radius: 25px;
        font-size: 11px;
      }
    }
  }

  &.-review {
    .m-notificationItem__icon {
      svg {
        path {
          fill: #ffc107 !important;
        }
      }
    }
  }

  &.-payment,
  &.-completed,
  &.-extend-time,
  &.-extend-time-accepted,
  &.-offer-accepted,
  &.-payment-made {
    .m-notificationItem__icon {
      svg {
        path {
          fill: #0AB145 !important;
        }
      }
    }

  }

  &.-completed-accepted,
  &.-case-started,
  &.-offer-sent,
  &.-upload-file {
    .m-notificationItem__icon {
      svg {
        path {
          fill: #499FD7 !important;
        }
      }
    }
  }
}
