@import 'styles/_variables';
@import 'styles/_mixins';

.m-chatMessage {
  max-width: 55%;
  width: 100%;
  margin-bottom: 20px;
  justify-content: flex-start;
  display: flex;

  &__message {
    position: relative;
    color: #3A5789;
    font-size: 14px;
    font-weight: 400;
    border-radius: 0 10px 10px;
    background-color: #E0E0E0;
    padding: 15px 15px 20px;
    width: auto;
    min-width: 100px;
    display: inline-flex;

    &--document,
    &--video {
      display: flex;
      align-items: center;

      svg {
        margin-right: 10px;
        width: 20px;
        height: auto;
        flex-shrink: 0;

        rect,
        path {
          fill: #3A5789;
        }
      }
    }

    &--loading,
    &--image {
      border-radius: 8px;
      overflow: hidden;

      &.-placeholder {
        width: 150px;
        background: #ccc;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      img {
        width: 150px;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    &.-loading,
    &.-image {
      width: 150px;
      height: 100px;
      padding: 5px;

      .m-chatMessage__sentAt {
        background: rgba(255, 255, 255, 0.8);
        text-align: right;
        padding: 2px 7px;
        border-radius: 5px;
        color: #333;
        opacity: 1 !important;
        bottom: 10px;
        right: 10px;
      }
    }
  }

  &__sentAt {
    color: #8498A5;
    font-size: 10px;
    font-weight: 400;
    text-transform: uppercase;
    position: absolute;
    bottom: 5px;
    right: 5px;
  }

  &.-system {
    border: none !important;
    justify-content: center !important;
    margin: 0 auto 40px;
    max-width: 100% !important;
    background: transparent !important;
    box-shadow: unset !important;
    overflow: visible !important;

    .m-chatMessage {
      &__button {
        padding: 5px 20px;
        border-radius: 5px;
        margin-top: 10px;
        background: $colorPrimary;
        font-weight: 600;
        text-transform: uppercase;
        color: #fff;
      }

      &__message {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column !important;
        border-radius: 6px;
        background: #dedede;
        color: #282828;
        text-align: center;
        font-size: 12px;
        padding: 15px 30px;

        &.-offerAccepted{
          background: #00BA88;
          color: #fff;
        }

        &.-linkMessage {
          background: #a8e9ff;
          transition: all 0.2s linear;

          &:hover {
            background: #77ddff;
          }
        }

        &.-videoRoom {
          cursor: default;
          background: #fff;
          border: 2px solid $colorPrimary;

          button {
            display: flex;
            align-items: center;
            padding: 10px 20px;

            svg {
              margin-right: 10px;

              path {
                fill: #fff;
              }
            }
          }
        }
      }

      &__sentAt {
        opacity: 0.7;
        color: #282828;
        top: 100%;
        right: 0;
        bottom: auto;
        z-index: 100;
        margin-top: 5px;
        width: 100%;
      }
    }
  }

  &.-sender {
    justify-content: flex-end;
    margin-left: auto;

    .m-chatMessage {
      &__message {
        border-radius: 10px 0 10px 10px;
        background-color: #499FD7;
        color: #fff;
        font-size: 14px;
        font-weight: 400;

        &--document,
        &--video {
          svg {
            rect,
            path {
              fill: #fff;
            }
          }
        }
      }

      &__sentAt {
        color: #A7DCFF;
        font-weight: 400;
        text-transform: uppercase;
      }
    }
  }

  &.-receiver {
    align-items: center;

    &:hover {
      .report {
        display: block;
      }
    }

    .report {
      display: none;
      margin-left: 15px;
      fill: #bbb;
      cursor: pointer;
      transition: all 0.2s linear;

      &:hover {
        fill: #dc3545;
        transition: all 0.2s linear;
      }
    }
  }

  &.-offer {
    padding: 0;
    overflow: hidden;
    background-color: #fff;
    max-width: unset;
    border: 1px solid #E4EAF5;
    border-radius: 10px;
  }

  &__offer {
    width: 100%;

    &--placeholder {
      width: 100%;
      height: 400px;
      background: #dbdbdb;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    header {
      width: 100%;
      background: #050E30;
      padding: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #fff;
      @include media-breakpoint-down(md) {
        flex-direction: column;
      }

      .m-chatMessage__offer {
        &--type {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          margin-right: auto;
          flex-shrink: 0;
          @include media-breakpoint-down(md) {
            width: 100%;
            flex-direction: row;
            justify-content: space-between;
          }

          &-title {
            display: flex;
            flex-direction: column;
            align-items: center;
          }

          &-icon {
            width: 32px;
            height: 32px;

            svg {
              width: 100%;
              height: 100%;
            }
          }

          strong {
            font-weight: 500;
            font-size: 18px;
            margin: 12px 0;
          }

          span {
            font-weight: 500;
            font-size: 12px;
            color: #050E30;
            background: #F2C94C;
            border-radius: 20px;
            padding: 4px 16px;
            line-height: 1;
          }
        }
      }

      ul {
        width: 100%;
        display: flex;
        flex-direction: column;
        flex: 1;
        max-width: 320px;

        @include media-breakpoint-down(md) {
          max-width: 100%;
          margin-top: 16px;
        }

        li {
          width: 100%;
          display: flex;
          align-items: center;
          line-height: 1;

          svg {
            width: 14px;
            height: 14px;
            flex-shrink: 0;
            margin-right: 8px;
          }

          &:not(:last-child) {
            border-bottom: 1px solid rgba(255, 255, 255, .2);
          }

          padding: 8px;

          span {
            font-weight: 500;
            font-size: 13px;
            padding-top: 1px;

            &:last-child {
              margin-left: auto;
            }
          }
        }
      }

    }

    main {
      padding: 15px;

      p {
        font-weight: 600;
        font-size: 13px;
        color: #26395A;
      }

      ul {
        margin-top: 16px;
        border: 1px solid #E4EAF5;
        border-radius: 10px;

        &.-hide {
          display: none;
        }

        li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 8px 16px;

          &:not(:last-child) {
            border-bottom: 1px solid #E4EAF5;
          }

          span {
            font-weight: 500;
            font-size: 13px;
            color: #3A5789;
          }
        }
      }

      .m-chatMessage__offer--paymentDetails {
        p {
          color: #666;
          margin-top: 16px;
        }
      }
    }

    footer {
      padding: 15px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;

      @include media-breakpoint-down(xs) {
        flex-direction: column;
      }

      button, a {
        background: #fff;
        color: #499FD7;
        border: 2px solid #499FD7;
        border-radius: 100px;
        font-weight: 500;
        font-size: 13px;
        padding: 8px 24px;
        line-height: 1;
        text-transform: uppercase;
        margin-left: 16px;
        transition: all .2s linear;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        @include media-breakpoint-down(xs) {
          margin: 8px 0 0;
        }

        &:hover {
          &:not(:disabled) {
            color: #fff;
            background: #050E30;
            border-color: #050E30;
          }
        }

        &:disabled {
          color: #fff;
          cursor: default;
        }

        &.-accepted {
          background: #00BA88;
          border-color: #00BA88;
        }
      }
    }

  }
}
