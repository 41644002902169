@import 'styles/_variables';
@import 'styles/_mixins';

.o-bookMeeting {
  background-color: #14132a;
  padding: 100px 30px;

  @include media-breakpoint-down(sm) {
    padding: 30px 30px 45px;
  }
  &__tabs {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 50px;

    @include media-breakpoint-down(xs) {
      flex-direction: column;
    }

    button {
      padding: 30px 50px;
      border: 1px solid $colorPrimary;
      display: flex;
      align-items: center;

      @include media-breakpoint-down(sm) {
        padding: 20px 30px;
      }

      @include media-breakpoint-down(xs) {
        margin-top: 15px;
      }

      svg {
        width: 25px;
        height: 25px;
        margin-right: 15px;

        circle,
        path {
          color: $colorPrimary;
          fill: $colorPrimary;
        }
      }

      span {
        color: #5a587d;
        font-size: 20px;
        font-weight: 600;
      }

      &.-active {
        background: $colorPrimary;

        span {
          color: #fff;
        }

        svg {
          circle,
          path {
            fill: #fff;
            color: #fff;
          }
        }
      }
    }
  }
  &__content {
    h3 {
      color: #fff;
      font-size: 45px;
      font-weight: 500;
      text-align: center;
      padding-bottom: 30px;
      @include media-breakpoint-down(sm) {
        font-size: 26px;
      }
    }
  }
}
