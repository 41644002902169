@import 'styles/_variables';
@import 'styles/_mixins';

.o-profilePhoto {
  width: 100%;
  background: rgba(255, 255, 255, .95);
  box-shadow: 0 0 0 rgba(66, 65, 67, 0.1);
  border: 1px solid #57595a;
  opacity: 0.95;
  border-radius: 5px;
  padding: 20px;

  &__content {
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 0 0 rgba(66, 65, 67, 0.1);
    border: 1px solid $colorPrimary;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 320px;
    overflow: hidden;

    &--text {
      color: #57595a;
      font-size: 15px;
      font-weight: 400;
      line-height: 28px;
      width: 100%;
      margin: 30px auto;
      text-align: center;
    }

    &--actions {
      display: flex;
      width: 100%;
      justify-content: center;

      &-button {
        padding: 15px 0;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 500;
        flex: 1;
        max-width: 140px;
        margin: 0 10px;
        transition: all .2s linear;

        &:hover {
          opacity: .8;
        }

        &.-takePhoto {
          border: 1px solid $colorPrimary;
          background-color: $colorPrimary;
          color: #fff;
        }

        &.-uploadPhoto {
          border: 1px solid #57595a;
          color: #57595a;
        }
      }
    }

    &--camera {
      width: 100%;
      height: 370px;
      position: relative;

      video{
        min-height: 370px;
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        transform: translate(-50%, -50%);
        width: 60px;
        height: 60px;
        border-radius: 50%;
        border: 4px solid #009ed2;
        border-right-color: transparent;
        transform-origin: center;
        animation: rotate 1s linear infinite;
      }

      &-close {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;
        background: $colorPrimary;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0 0 0 5px;

        svg {
          path {
            fill: #fff
          }
        }
      }
    }
    &--crop{
      padding: 20px;
      overflow: hidden;
      &ped{
        border-radius: 100%;
        overflow: hidden;
        border: 2px solid $colorPrimary;
      }
      .ReactCrop{
        border-radius: 5px;
      }
      &Actions{
        display: flex;
        justify-content: center;
        margin-top: 30px;
        &-button {
          padding: 15px 0;
          border-radius: 5px;
          font-size: 14px;
          font-weight: 500;
          flex: 1;
          max-width: 140px;
          margin: 0 10px;
          transition: all .2s linear;

          &:hover {
            opacity: .8;
          }

          &.-savePhoto{
            border: 1px solid $colorPrimary;
            background-color: $colorPrimary;
            color: #fff;
          }

          &.-changePhoto {
            border: 1px solid #57595a;
            color: #57595a;
          }
        }
      }
    }
  }
}

@keyframes rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
