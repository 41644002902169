@import 'styles/_variables';
@import 'styles/_mixins';

.a-title{
  color: #222;
  font-size: 45px;
  font-weight: 600;
  line-height: 35px;
  @include media-breakpoint-down(lg){
    font-size: 40px;
  }
  @include media-breakpoint-down(sm){
    font-size: 30px;
  }
}
