@import 'styles/_variables';
@import 'styles/_mixins';

.o-appStores {
  text-align: center;

  p {
    width: 100%;
    display: inline-block;
    padding: 40px 0 25px;
    color: #888;
    font-size: 20px;
    font-weight: 400;
    line-height: 35px;
    @include media-breakpoint-down(sm) {
      line-height: 1.2;
      font-size: 18px;
      padding: 15px 0;
    }
  }

  &__buttons {
    width: 100%;
    display: flex;
    justify-content: center;
    opacity: 0.35;

    a {
      margin: 15px;
      transition: all 0.2s linear;

      @include media-breakpoint-down(sm) {
        margin: 0;
        &:not(:last-child) {
          margin-right: 15px;
        }
      }
      &:hover {
        opacity: 0.8;
      }
    }
  }

  &__soon {
    color: #888;
    width: auto;
    display: inline-block;
    padding: 20px;
    border-radius: 8px;
    font-size: 22px;
  }
}
