@import 'styles/_variables';
@import 'styles/_mixins';

.o-homeSearch {
  position: relative;
  background: url('../../../images/bg-header.jpeg') bottom center no-repeat;
  background-size: cover;
  @include media-breakpoint-down(md) {
    background: #11132e url('../../../images/bg-header1024x1024.jpeg') bottom
      center no-repeat;
  }

  &__video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    video{
      height: 100%;
      min-height: 860px;
    }
  }

  > .flex {
    @include media-breakpoint-down(md) {
      flex-direction: column;
    }
  }

  &__form {
    z-index: 2;
    width: 100%;
    max-width: 40%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 30px;
    @include media-breakpoint-down(md) {
      max-width: 100%;
      margin: auto;
      padding: 150px 15px 50px;
      min-height: unset;
    }

    &--title {
      color: $colorPrimary;
      font-size: 32px;
      font-weight: 600;
      margin-bottom: 50px;
      width: 100%;
      text-align: center;
      max-width: 360px;

      @include media-breakpoint-down(lg) {
        font-size: 40px;
        line-height: 50px;
      }
      @include media-breakpoint-down(md) {
        text-align: center;
        margin: auto;
        margin-bottom: 30px;
      }
    }

    &--fields {
      max-width: 360px;
      @include media-breakpoint-down(md) {
        margin: auto;
      }
    }
  }

  &__map {
    width: 100%;
    max-width: 60%;
    height: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    z-index: 2;
    padding: 0 30px;
    @include media-breakpoint-down(md) {
      padding-bottom: 30px;
    }
    img {
      width: 100%;
      max-width: 100%;
    }

    @include media-breakpoint-down(md) {
      max-width: 100%;
      position: relative;
      min-height: unset;
    }

    &--action {
      width: 100%;
      height: 100%;
      overflow: hidden;
      position: relative;
      @include media-breakpoint-down(md) {
      }

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
