@import 'styles/_variables';
@import 'styles/_mixins';

.o-clientProfile {
  &__container {
    max-width: 1200px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 50px 0;
    @include media-breakpoint-down(sm) {
      flex-direction: column;
      padding: 30px 0;
    }
  }

  &__aside {
    width: 100%;
    max-width: 360px;
    padding-top: 15px;
    padding-right: 15px;

    @include media-breakpoint-down(lg) {
      max-width: 320px;
      margin-left: 15px;
    }

    @include media-breakpoint-down(sm) {
      max-width: 100%;
      padding-right: 30px;
    }

    &--box {
      width: 100%;
      border-radius: 10px;
      background: rgba(5, 14, 48, 0.6);
      padding: 20px;
      margin-bottom: 25px;
      display: flex;
      flex-direction: column;

      &.-center {
        justify-content: center;
        align-items: center;
      }

      &-avatar {
        border-radius: 100%;
        margin-top: -50px;
        border: 3px solid #00ba88;
        padding: 7px;
        position: relative;

        .-indicator {
          position: absolute;
          top: 15px;
          right: 10px;
          width: 22px;
          height: 22px;
          border-radius: 100%;
          background: #00ba88;
          border: 3px solid #26395a;
          display: flex;
          box-shadow: 0 0 7px rgba(255, 255, 255, 0.3);
        }
      }

      &-name {
        color: #fff;
        font-weight: 500;
        font-size: 22px;
        text-transform: uppercase;
        margin: 15px 0 5px;
        line-height: 1;
      }
    }

    .a-whiteBox {
      width: 100%;
      max-width: 100%;
      margin: 15px 0;

      &__header {
        &--icon {
          color: #499fd7;
          padding: 4px;

          svg {
            width: 100%;
            height: 100%;
          }
        }

        &--actions {
          display: flex;
          align-items: center;
          justify-content: center;

          button {
            svg {
              width: 22px;
              height: 22px;
              color: #fff;
            }
          }
        }
      }

      &__main {
        min-height: 60px;
        justify-content: flex-start;

        input {
          border-radius: 10px;
          padding: 0 16px;
          height: 40px;
          outline: none;
          background: transparent;
          border: 1px solid rgba(255, 255, 255, 0.7);
          color: #fff;
          width: 100%;

          &.-space {
            margin-bottom: 16px;
          }
        }

        span {
          color: #fff;
          font-size: 14px;
          width: 100%;
        }

        small {
          color: #fff;
          font-size: 12px;
          line-height: 1;
        }
      }

      &.-bankAccount {
        .a-whiteBox__main {
          small {
            color: #fff;

            &:not(.text-xs) {
              font-size: 14px;
            }
          }

          ul {
            width: 100%;

            li {
              margin-top: 16px;
              width: 100%;
              background: rgba(255, 255, 255, 0.2);
              display: flex;
              flex-direction: column;
              border-radius: 10px;
              font-size: 15px;
              color: #fff;
              line-height: 1;

              input {
                margin: 16px;
                max-width: calc(100% - 32px);
              }

              span {
                font-weight: 500;
                width: 100%;
                padding: 16px;
                border-bottom: 1px solid rgba(255, 255, 255, 0.1);
              }

              b {
                font-weight: 500;
                padding: 16px;
              }

              small {
                color: #fff;
                padding: 0 16px 16px;
                font-size: 12px;
                line-height: 1.2;
              }
            }
          }
        }
      }

      &.-linkedIn {
        .a-whiteBox {
          &__main {
            a {
              display: flex;
              align-items: center;
              justify-content: center;
              border: 1px solid rgba(255, 255, 255, .5);
              border-radius: 10px;
              width: 100%;
              transition: all .2s linear;

              .-icon {
                padding: 8px;
                color: #fff;
              }

              span {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                padding: 8px;
                border-left: 1px solid rgba(255, 255, 255, .5);
              }

              &:hover {
                background: #499fd7;
              }
            }

            button {
              border: 2px solid #ffffff;
              border-radius: 40px;
              font-weight: 500;
              font-size: 14px;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 8px 16px;
              color: #fff;
              transition: all .2s linear;

              svg {
                width: 24px;
                height: 24px;
                margin-left: 6px;
              }

              &:hover {
                background: rgba(255, 255, 255, .1);
              }
            }
          }
        }
      }
    }
  }

  &__content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    max-width: calc(100% - 360px);

    @include media-breakpoint-down(lg) {
      max-width: calc(100% - 320px);
    }
    @include media-breakpoint-down(sm) {
      max-width: 100%
    }

    .a-whiteBox {
      max-width: calc(50% - 30px);
      @include media-breakpoint-down(md) {
        max-width: 100%;
      }

      &__header {
        &--icon {
          svg {
            color: #499FD7;
          }
        }

      }

      &.-socialLinks {
        main {
          width: 100%;

          .m-generic {
            &:not(:last-child) {
              padding-bottom: 15px;
              margin-bottom: 15px;
              border-bottom: 1px dashed #E9EBEF;
            }
          }
        }
      }

      &.-notifications {
        main {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;

          .m-generic {
            min-height: 100px;

            &:not(:last-child) {
              padding-bottom: 15px;
              margin-bottom: 15px;
              border-bottom: 1px dashed #E9EBEF;
            }

            &__component {
              strong {
                margin-bottom: 8px;
              }

              p {
                max-width: 200px;
              }
            }
          }
        }
      }

      &.-removeAccount {
        max-width: 100%;

        .a-whiteBox {
          &__header--icon {
            svg {
              width: 22px;
              height: 22px;
            }
          }

          &__main {
            min-height: unset;
            display: flex;
            align-items: center;
            flex-direction: row;
            flex-wrap: nowrap;
            .m-removeAccount {
              &__text {
                display: flex;
                flex-direction: column;
                max-width: 60%;
                p {
                  color: #fff;
                  font-weight: 500;
                  font-size: 14px;
                  &:first-child {
                    margin-bottom: 16px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
