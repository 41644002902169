@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700|Roboto:wght@500|Poppins:300,400,500,600|Source+Code+Pro:wght@500&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Open+Sans:300,400,600,700&family=Roboto:wght@400;500&family=Source+Code+Pro:wght@500&display=swap');
@import 'styles/_variables';

body, html {
  font-family: $defaultFontFamily;
  overflow-x: hidden;

  .App {
    overflow-x: hidden;
  }
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
