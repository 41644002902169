@import 'styles/_variables';
@import 'styles/_mixins';

.m-profileTeam {
  width: 100%;
  max-width: 100%;
  main {
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    &--empty {
      font-weight: 500;
      font-size: 13px;
      color: #000000;
    }
    &.-showAll {
      .m-profileTeam__item {
        display: block;
      }
    }
  }
  &__item {
    margin: 15px;
    max-width: calc(25% - 30px);
    border-radius: 30px 0 30px 0;
    background-color: #ffffff;
    box-shadow: 0 20px 19px rgba(0, 0, 0, 0.05);
    overflow: hidden;
    &:nth-of-type(n + 5) {
      display: none;
    }
    @include media-breakpoint-down(lg) {
      max-width: calc(50% - 30px);
    }
    @include media-breakpoint-down(md) {
      max-width: calc(33% - 30px);
      &:nth-of-type(n + 4) {
        display: none;
      }
    }
    @include media-breakpoint-down(sm) {
      max-width: calc(50% - 30px);
      &:nth-of-type(n + 3) {
        display: none;
      }
    }
    @include media-breakpoint-down(xs) {
      max-width: 100%;
      margin: 15px auto;
    }

    &--image {
      overflow: hidden;
      border-radius: 30px 0 0 0;

      img {
        max-width: 100%;
      }
    }

    &--info {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 25px;
      text-align: center;

      @include media-breakpoint-down(lg) {
        padding: 60px 15px;
      }

      strong {
        color: #2d3c53;
        font-size: 20px;
        font-weight: 400;
      }

      b {
        color: $colorPrimary;
        font-size: 11px;
        font-weight: 400;
      }

      span {
        color: #202233;
        font-size: 16px;
        font-weight: 500;
        margin-top: 15px;
      }
    }
  }
}
