@import 'styles/_variables';
@import 'styles/_mixins';

.a-lawyerStatus {
  width: 17px;
  height: 17px;
  max-width: 17px;
  max-height: 17px;
  background: #999;
  display: inline-block;
  border-radius: 100%;
  position: relative;
  overflow: hidden;

  &.-online {
    background-color: #2bbd19;

    &:before {
      border-radius: 100%;
      content: '';
      width: 90%;
      height: 90%;
      background: radial-gradient(circle, #89ff70 0%, #2bbd19 100%);
      -webkit-animation: flash linear 1s infinite;
      animation: flash linear 1s infinite;
      z-index: 2;
      position: absolute;
      top: 1px;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
    }
  }

  &.-away {
    background-color: #dca71c;

    &:before {
      border-radius: 100%;
      content: '';
      width: 90%;
      height: 90%;
      background: radial-gradient(circle, #fff421 0%, #dca71c 100%);
      -webkit-animation: flash linear 1s infinite;
      animation: flash linear 1s infinite;
      z-index: 2;
      position: absolute;
      top: 1px;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
    }
  }

  @include media-breakpoint-down(lg) {
    width: 13px;
    height: 13px;
  }
  @include media-breakpoint-down(sm) {
    min-width: 13px;
    min-height: 13px;
  }
}


@-webkit-keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .1;
  }
  100% {
    opacity: 1;
  }
}
