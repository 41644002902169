@import "styles/_variables";
@import "styles/_mixins";

.p-panelMessagesPage {

  &__container {
    overflow: hidden;
    z-index: 2;
    //position: relative;
    max-width: 1200px;
    max-height: calc(100vh - 84px);
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 24px;
    min-height: 400px;
    margin: 50px auto;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }

    @media (max-height: 800px) {
      margin: 24px auto;
      max-height: calc(100vh - 32px);
    }

  }

  &__contacts {
    width: 100%;
    max-width: 360px;
    background: #fff;
    border-right: 2px solid #E4EAF5;
    border-radius: 10px 0 0 10px;
    overflow: hidden;
    min-height: 100%;

    @media (min-height: 450px) {
      max-height: calc(100vh - 184px);
    }
    @media (max-height: 800px) {
      max-height: calc(100vh - 132px);
    }

    &--header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 90px;
      padding: 16px;
      border-bottom: 1px solid rgba(38, 57, 90, .2);
      color: #499FD7;
      @media (max-height: 800px) {
        height: 70px;
      }

      svg {
        width: 32px;
        height: 32px;
        margin-right: 16px;
      }

      h3 {
        font-weight: 400;
        font-size: 22px;
      }

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-size: 12px;
        color: #999;
        background: #eee;
        border-radius: 20px;
        padding: 2px 12px;
        text-transform: capitalize;
        margin-left: auto;

        &:before {
          content: '';
          width: 6px;
          height: 6px;
          border-radius: 3px;
          display: flex;
          flex-shrink: 0;
          background: #999;
          margin-right: 4px;
        }

        &.-online {
          color: #008964;
          background: #CDFFCD;

          &:before {
            background: #008964;
          }
        }

        &.-away {
          color: #856b00;
          background: #facf00;

          &:before {
            background: #856b00;
          }
        }
      }
    }

    &--search {
      padding: 12px 16px;
      border-bottom: 1px solid rgba(38, 57, 90, .2);
      margin-bottom: 16px;

      &-field {
        background: #F2F2F2;
        border: none;
        border-radius: 100px;
        display: flex;
        align-items: center;
        padding: 0 16px;

        svg {
          flex-shrink: 0;
        }

        input {
          height: 40px;
          width: 100%;
          padding-right: 16px;
          background: transparent;
          color: #828282;
          outline: none;
        }
      }
    }

    &--empty {
      display: flex;
      flex: 1;
      height: 100%;
      min-height: calc(100vh - 84px);
      align-items: center;
      justify-content: center;
      font-size: 22px;
      opacity: .2;
      padding-bottom: 42px;
      @include media-breakpoint-down(sm) {
        min-height: calc(100vh - 69px);
      }
    }
  }

  &__content {
    width: 100%;
    border-radius: 0 10px 10px 0;
    overflow: hidden;
    min-height: 100%;
    position: relative;
    background: #fff;
    @media (min-height: 450px) {
      max-height: calc(100vh - 184px);
    }
    @media (max-height: 800px) {
      max-height: calc(100vh - 132px);
    }
  }

  @include media-breakpoint-down(md) {
    &__contacts {
      max-width: 300px;
    }
  }
  @include media-breakpoint-down(sm) {
    &__container {
      position: unset;
      max-height: unset;
    }
    &__contacts {
      max-width: 100%;
      border-radius: 10px;
      margin: 24px 0;
    }

    &__content {
      position: fixed;
      top: 0;
      left: 100%;
      max-width: 100%;
      z-index: 1299;
      margin: 0;
      height: 100vh;
      max-height: unset;
      border-radius: 0;
      opacity: 0;

      &.-active {
        opacity: 1;
        left: 0;
      }
    }
  }
}
