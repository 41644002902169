@import 'styles/_variables';
@import 'styles/_mixins';

.o-chatConversation {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: relative;
  max-height: vh(100);

  @include media-breakpoint-up(sm){
    min-height: 100%;
  }
  &__empty {
    text-align: center;
    align-self: center;
    justify-self: center;
    margin: auto;
    font-size: 22px;
    opacity: 0.2;
    padding-bottom: 42px;
    max-width: 460px;
  }

  &__header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    min-height: 90px;
    width: 100%;
    background: #499FD7;
    position: relative;
    padding: 15px 30px 14px 20px;

    @media (max-height: 800px) {
      height: 70px;
      min-height: 70px;
    }

    &--close {
      display: none;
      @include media-breakpoint-down(sm) {
        display: inline-block;
      }

      svg {
        width: 15px;

        path {
          fill: #fff
        }
      }

      margin-right: 10px;
    }

    &--avatar {
      width: 60px;
      height: 60px;
      flex-shrink: 0;
      margin-right: 15px;
      position: relative;

      &:after {
        position: absolute;
        content: '';
        width: 16px;
        height: 16px;
        border: 2px solid #fff;
        background-color: #938F8F;
        border-radius: 100%;
        right: 2px;
        bottom: 2px;
      }

      img {
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-radius: 100%;
      }

      &.-away,
      &.-online {
        &:after {
          display: block;
        }
      }

      &.-away {
        &:after {
          background: #facf00;
        }
      }

      &.-online {
        &:after {
          background-color: #00BA88;
        }
      }
    }

    &--info {
      display: flex;
      align-items: center;
      overflow: hidden;
      @include media-breakpoint-down(md) {
        flex-direction: column;
        align-items: flex-start;
      }

      strong {
        font-weight: 500;
        font-size: 22px;
        color: #fff;
        width: 100%;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @include media-breakpoint-down(sm) {
          font-size: 18px;
        }
      }

      button {
        margin-left: 24px;
        border: 2px solid #fff;
        border-radius: 20px;
        font-weight: 500;
        font-size: 12px;
        display: flex;
        align-items: center;
        text-align: center;
        padding: 8px 16px;
        color: #fff;
        transition: all .2s linear;
        white-space: nowrap;

        &:hover {
          background: rgb(38, 57, 90);
          border-color: rgb(38, 57, 90);
        }

        @include media-breakpoint-down(md) {
          margin: 4px 0 8px;
          padding: 4px 16px;
        }
        @include media-breakpoint-down(sm) {
          border-width: 1px;
        }
      }
    }

    &--actions {
      display: flex;
      margin-left: auto;
      justify-content: space-between;
      align-items: center;

      button {
        margin-left: 30px;
        transition: all 0.2s linear;

        @include media-breakpoint-down(sm) {
          margin-left: 15px;
          &:first-child {
            margin-left: 0;
          }
        }

        &:hover {
          opacity: 0.7;
        }
      }
    }
  }

  &__notice {
    background-color: #fff;
    padding: 7px 10px;
    border-top: 1px solid #f6f6f6;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      display: block;
      font-size: 12px;
      color: #777;
    }

    svg {
      cursor: pointer;
      margin-right: 10px;
      margin-left: 20px;
      width: 12px;
      fill: #ccc;
      flex-shrink: 0;
    }
  }

  &__messages {
    z-index: 1;
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1 0;
    position: relative;
    overflow: hidden;

    &--day {
      width: 100%;
      display: inline-flex;
      text-align: center;
      margin-bottom: 16px;
      border-bottom: 1px solid #eee;
      padding: 8px 16px;
      position: sticky;
      top: 0;
      background: #fff;
      z-index: 2;

      span {
        width: 120px;
        background: #26395A;
        color: #fff;
        font-size: 12px;
        margin: auto;
        padding: 4px 16px;
        border-radius: 10px;
      }
    }

    &--scrollArea{
      height: auto;
      overflow-y: auto;
      flex-shrink: 0;
      max-height: 100%;
    }

  }

  &__profile {
    position: absolute;
    background: rgba(5, 14, 48, .95);
    width: 295px;
    height: 100%;
    top: 0;
    right: -300px;
    z-index: 999;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 0;
    transition: all .2s linear;

    @include media-breakpoint-down(xs) {
      width: 100%;

      right: -100%;
    }

    &.-show {
      right: 0;
    }

    &--close {
      position: absolute;
      top: 16px;
      left: 16px;
      outline: none;

      svg {
        path {
          fill: #fff
        }
      }
    }

    &--avatar {
      width: 140px;
      height: 140px;
      flex-shrink: 0;
      position: relative;

      &:after {
        position: absolute;
        content: '';
        width: 16px;
        height: 16px;
        border: 2px solid #fff;
        background-color: #938F8F;
        border-radius: 100%;
        right: 13px;
        bottom: 13px;
      }

      img {
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-radius: 100%;
      }

      &.-away,
      &.-online {
        &:after {
          display: block;
        }
      }

      &.-away {
        &:after {
          background: #facf00;
        }
      }

      &.-online {
        &:after {
          background-color: #00BA88;
        }
      }
    }

    &--name {
      padding: 16px 0 0;
      color: #fff;
      font-weight: 500;
      font-size: 22px;
    }

    &--box {
      width: 100%;

      .a-whiteBox {
        width: 100%;

        &__header--icon {
          color: #499FD7;
          padding: 2px;
          svg{
            height: 100%;
          }
        }

        &__main {
          display: flex;
          flex-direction: column;
          min-height: unset;
          color: #fff;
          align-items: flex-start;
        }
      }
    }

    &--viewProfile {
      margin: 16px 0 8px;

      a {
        border: 2px solid #fff;
        border-radius: 20px;
        font-weight: 500;
        font-size: 12px;
        display: flex;
        align-items: center;
        text-align: center;
        padding: 8px 16px;
        color: #fff;
        transition: all .2s linear;
        white-space: nowrap;

        &:hover {
          background: rgb(38, 57, 90);
          border-color: rgb(38, 57, 90);
        }

        @include media-breakpoint-down(md) {
          margin: 8px 0 0;
          padding: 4px 16px;
        }
      }
    }
  }

  &__form {
    bottom: 0;
    width: 100%;
    z-index: 99;
    flex-shrink: 0;
    margin-top: auto;
  }
}
