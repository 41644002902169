@import 'styles/_variables';
@import 'styles/_mixins';

.m-hourPrice {
  width: 100%;
  &__box {
    display: flex;
    padding: 16px 0;
    border-radius: 6px;
    align-items: center;

    &--icon{
      background: #499FD7;
      width: 34px;
      flex-shrink: 0;
      height: 34px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      svg{
        color: #fff;
      }
    }

    span{
      margin-left: 16px;
      font-weight: 500;
      font-size: 15px;
      color: #26395A;
    }

    button {
      margin-left: auto;
      outline: none;

      svg {
        width: 22px;
        height: 22px;
      }
    }

    &--field{
      width: 100%;
      border: 1px solid #ccc;
      border-radius: 40px;
      margin:0 16px;
      background: #eee;
      display: flex;
      align-items: center;
      input{
        width: 40%;
        height: 34px;
        padding: 0 15px;
        margin-right: 10px;
        border-right: 1px solid #ccc;
        outline: none;
        border-radius: 40px 0 0 40px;
        text-align: right;
      }
      span{
        font-size: 14px;
        text-align: left;
        padding: 0;
        margin: 0;
      }

    }
  }

}
