@import "styles/_variables";
@import "styles/_mixins";

.p-cases {
  position: relative;
  z-index: 10;

  &__container {
    max-width: 1200px;
    margin: auto;
    display: flex;
    width: 100%;
    padding: 30px 0;
    flex-wrap: wrap;
    @include media-breakpoint-down(sm) {
      flex-direction: column;
      padding: 30px 0;
    }
  }

  &__header {
    width: 100%;
    padding: 0 15px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include media-breakpoint-down(sm) {
      flex-wrap: wrap;
      justify-content: center;
    }

    &--howToSendAnOffer {
      max-width: 415px;
      width: 100%;
      height: 115px;
      overflow: hidden;
      position: relative;
      border-radius: 10px;
      @include media-breakpoint-down(sm) {
        margin: 0 auto 30px;
        max-width: 100%;
        height: 140px;
      }

      img {
        width: 100%;
        object-fit: cover;
        max-width: 100%;
      }

      &-text {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        z-index: 2;
        top: 0;
        left: 0;
        padding: 20px;

        strong {
          padding-left: 15px;
          color: #fff;
          font-weight: 500;
          font-size: 18px;
        }
      }
    }

    &--help {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      @include media-breakpoint-down(sm) {
        justify-content: center;
      }

      &-item {
        line-height: 1;
        display: flex;
        align-items: center;
        padding-left: 30px;
        @include media-breakpoint-down(sm) {
          justify-content: center;
          padding: 0 15px;
        }
        @include media-breakpoint-down(xs) {
          flex-direction: column;
        }

        span {
          padding-left: 10px;
          color: #fff;
          font-weight: 500;
          font-size: 14px;
          @include media-breakpoint-down(xs) {
            padding: 10px 0 0;
            text-align: center;
            line-height: 1.3;
          }
        }
      }
    }
  }

  &__main {
    width: 100%;

    .a-whiteBox__main {
      justify-content: flex-start;
      align-items: unset;
      padding: 8px;

      .m-caseCard {
        height: unset;
        max-width: calc(25% - 16px) !important;
        margin: 8px;

        @include media-breakpoint-down(lg) {
          max-width: calc(33.33% - 16px) !important;
        }
        @include media-breakpoint-down(md) {
          max-width: calc(50% - 16px) !important;
        }
        @include media-breakpoint-down(xs) {
          max-width: 400px !important;
          margin: 8px auto;
        }
      }
    }

    //.a-whiteBox__main {
    //  justify-content: flex-start;
    //  align-items: unset;
    //  position: relative;
    //  min-height: 200px;
    //
    //  .m-caseCard {
    //    height: unset;
    //    max-width: calc(25% - 30px) !important;
    //    margin: 15px 15px 30px;
    //  }
    //}
  }

  &__items {
    &--icon {
      path {
        fill: #499FD7
      }
    }

    &--empty {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 22px;
      font-weight: 500;
      opacity: 0.8;
      color: #fff;
    }
  }
}
