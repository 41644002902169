@import 'styles/_variables';
@import 'styles/_mixins';

.m-panelSidebar {
  width: 100%;
  max-width: 300px;
  padding-top: 15px;

  padding-right: 15px;
  @include media-breakpoint-down(md) {
    max-width: 320px;
  }
  @include media-breakpoint-down(sm) {
    display: none;
  }
  &__box {
    width: 100%;
    border-radius: 10px;
    background: rgba(5, 14, 48, 0.6);
    padding: 20px;
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;
    &.-center{
      justify-content: center;
      align-items: center;
    }
    &--avatar {
      border-radius: 100%;
      margin-top: -50px;
      border: 3px solid #00ba88;
      padding: 7px;
      position: relative;
      .-indicator {
        position: absolute;
        top: 15px;
        right: 10px;
        width: 22px;
        height: 22px;
        border-radius: 100%;
        background: #00ba88;
        border: 3px solid #26395a;
        display: flex;
        box-shadow: 0 0 7px rgba(255, 255, 255, 0.3);
      }
    }
    &--name {
      font-weight: 500;
      font-size: 22px;
      text-transform: uppercase;
      margin: 15px 0 5px;
      line-height: 1;
    }
    &--viewProfile {
      margin-top: 20px;
      margin-bottom: 20px;
      a {
        background: #26395a;
        border-radius: 40px;
        font-weight: 500;
        font-size: 12px;
        padding: 10px 20px;
        transition: all .2s linear;
        &:hover{
          background: #395280;
        }
      }
    }
    &--item{
      display: flex;
      align-items: center;
      margin: 5px 0;
      svg{
        margin-right: 10px;
      }
      span{
        font-size: 14px;
        font-weight: 500;
      }
    }
    &.-address{
      overflow: hidden;
      padding: 0;
      flex-direction: column;
      a[href^="http://maps.google.com/maps"],a[href^="https://maps.google.com/maps"] {
        display: none !important;
      }
      .gmnoprint a,.gmnoprint span,.gm-style-cc {
        display: none;
      }
    }

    &--map{
      width: 100%;
      height: 160px;
      position: relative;
      &-pin{
        width: 83px;
        height: 91px;
        display: flex;
        background: url('../../../images/bg-pin.png') no-repeat 0 0;
        align-items: flex-start;
        justify-content: center;
        margin-top: -45px;
        margin-left: -41px;
        img{
          width: 40px;
          height: 40px;
          border-radius: 100%;
          margin-top: 15px;
        }
      }
    }
    &--address{
      display: flex;
      flex-direction: column;
      padding: 20px 20px 30px;
      align-items: center;
      justify-content: center;
      margin-top: -45px;
      z-index: 10;
      line-height: 1;
      svg{
        margin-bottom: 20px;
      }
      b{
        font-size: 18px;
        color: #499FD7;
        margin-bottom: 10px;
        font-weight: 500;
      }
      p{
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}
