@import "styles/_variables";
@import "styles/_mixins";

.p-manageCase {
  max-width: 1140px;
  padding: 30px 15px;
  margin: auto;
  display: flex;
  align-items: flex-start;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }

  .m-caseCard {
    margin: 10px 0;
  }

  .a-whiteBox {
    &.-timeline {
      .a-whiteBox__main {
        justify-content: flex-start;
        align-items: flex-start;
      }
    }
  }

  &__timeline {
    width: 100%;
    position: relative;
    padding-left: 8px;

    &:before {
      content: '';
      width: 1px;
      height: 100%;
      border-left: 1px dashed #CDD0D3;
      position: absolute;
    }

  }
}
