@import 'styles/_variables';
@import 'styles/_mixins';

.p-joinPage {
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: calc(100vh - 91px);
  width: 100%;
  height: 100vh;
  position: relative;
  margin-top: -30px;
  z-index: 0;
  @media (max-width: 767px) {
    height: 100%;
    min-height: calc(100vh - 91px);
    flex-direction: column;
    justify-content: flex-start;
    max-height: unset;
  }
  &:before,
  &:after {
    content: '';
    width: 50%;
    height: 100%;
    background-color: #11132c;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    @media (max-width: 767px) {
      display: none;
    }
  }
  &:after {
    left: auto;
    right: 0;
    background: $colorPrimary;
  }
  h2 {
    color: #fff;
    font-size: 55px;
    font-weight: 500;
    margin-bottom: 15px;
    white-space: nowrap;
    @media (max-width: 1199px) {
      font-size: 40px;
    }

    @media (max-width: 1023px) {
      font-size: 30px;
    }
  }
  p {
    color: #eee;
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 30px;
    @media (max-width: 1023px) {
      font-size: 16px;
    }
  }
  &__lawyer {
    position: relative;
    z-index: 2;
    flex: 0;
    &--image {
      display: none;
    }

    @media (max-width: 767px) {
      display: none;
      background-color: #11132c;
      width: 100%;
      height: 100%;
      flex: 1;
      border-bottom: 1px solid #ccc;
      &--image {
        padding-top: 30px;
        margin-top: auto;
        display: inline-block;
        img {
          max-width: 100%;
          margin: auto;
        }
      }
      &.-active {
        display: flex;
        flex-direction: column;
        padding: 130px 30px 0;
      }
    }
  }
  &__image {
    position: relative;
    z-index: 2;
    display: block;
    padding: 100px;
    @media (max-width: 1199px) {
      padding: 100px 50px;
    }
    @media (max-width: 767px) {
      display: none;
    }
    img {
      height: 100%;
      max-height: 640px;
      @media (max-width: 1199px) {
        max-height: 440px;
      }
      @media (max-width: 1023px) {
        max-height: 340px;
      }
    }
  }
  &__client {
    position: relative;
    z-index: 2;
    flex: 0;

    &--image {
      display: none;
    }

    @media (max-width: 767px) {
      display: none;
      background: $colorPrimary;
      width: 100%;
      height: 100%;
      flex: 1;
      &--image {
        padding-top: 30px;
        margin-top: auto;
        display: inline-block;
        img {
          max-width: 100%;
          margin: auto;
        }
      }

      &.-active {
        display: flex;
        flex-direction: column;
        padding: 130px 30px 0;
      }
    }
    button {
      background-color: #11132c;
    }
  }
  &__tabs {
    display: none;
    @media (max-width: 767px) {
      display: flex;
      justify-content: space-between;
      position: absolute;
      top: 0;
      left: 30px;
      padding: 30px 0 0;
      width: calc(100% - 60px);
      z-index: 10;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      button {
        color: #eee;
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        width: 50%;
        border: none;
        position: relative;
        padding: 15px 0;
        outline: none;
        &.-active {
          &:after {
            content: '';
            width: 100%;
            height: 3px;
            background: #11132c;
            display: block;
            position: absolute;
            top: 100%;
          }
          &.-lawyer {
            &:after {
              background: $colorPrimary;
            }
          }
        }
      }
    }
  }
}
