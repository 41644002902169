@import 'styles/_variables';
@import 'styles/_mixins';

.o-sponsorsCarousel {
  display: flex;
  position: relative;
  justify-content: space-between;
  @include media-breakpoint-down(md) {
    margin-bottom: 100px;
  }

  &__item {
    position: relative;
    width: 100%;
    max-width: calc(50% - 30px);
    @include media-breakpoint-down(md) {
      max-width: 100%;
      display: none;
    }

    &:before,
    &:after {
      content: '';
      width: 100%;
      height: 100%;
      display: inline-block;
      box-shadow: 0 12px 40px rgba(83, 200, 249, 0.2);
      border-radius: 10px;
      background-color: #fff;
      position: absolute;
      top: 30px;
      left: 0;
      transform: scale(0.96);
      z-index: 2;
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    &:after {
      z-index: 1;
      transform: scale(0.92);
      top: 60px;
    }
    &--content {
      box-shadow: 0 12px 40px rgba(83, 200, 249, 0.2);
      border-radius: 10px;
      background-color: #fff;
      position: relative;
      z-index: 3;
      padding: 50px;
      text-align: left;
      height: 100%;
      display: flex;
      flex-direction: column;
      @include media-breakpoint-down(sm) {
        padding: 30px;
      }
      .o-sponsorsCarousel__info {
        margin-top: auto;
      }
    }

    @include media-breakpoint-down(sm) {
      padding: 20px;
    }

    &.-active {
      display: block;
    }
  }

  &__text {
    color: #888;
    font-size: 14px;
    font-weight: 400;

    @include media-breakpoint-down(md) {
      font-size: 20px;
      line-height: 35px;
    }
    @include media-breakpoint-down(sm) {
      color: #888;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
    }
  }

  &__author {
    display: flex;
    align-items: center;
    margin-top: 30px;
    @include media-breakpoint-down(xs) {
      flex-direction: column;
      justify-content: center;
      text-align: center;
    }

    &Avatar {
      width: 90px;
      height: 90px;
      min-width: 90px;
      border: 3px solid $colorPrimary;
      overflow: hidden;
      border-radius: 10px;
      margin-right: 15px;

      @include media-breakpoint-down(sm) {
        margin-right: 0;
        margin-bottom: 10px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &Info {
      display: flex;
      flex-direction: column;
      margin-right: 30px;
      padding-right: 30px;
      border-right: 2px solid #ddd;

      @include media-breakpoint-down(sm) {
        border: none;
        margin-right: 0;
        padding: 0;
        width: 100%;
      }

      b {
        color: #222;
        font-size: 18px;
        font-weight: 600;
        @include media-breakpoint-down(sm) {
          font-size: 16px;
        }
      }

      span {
        color: $colorPrimary;
        font-size: 16px;
        font-weight: 400;
        @include media-breakpoint-down(sm) {
          font-size: 14px;
        }
      }

      &--business {
        display: none;

        @include media-breakpoint-down(sm) {
          display: inline-block;
          margin-top: 15px;
          text-align: center;
          img {
            margin: auto;
          }
        }
      }
    }

    &Business {
      img {
        max-width: 120px;
        @include media-breakpoint-down(xs) {
          max-width: 100%;
        }
      }

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }

  &__pager {
    z-index: 4;
    position: absolute;
    bottom: 50px;
    right: 50px;
    display: none;

    @include media-breakpoint-down(md) {
      top: 100%;
      bottom: auto;
      right: auto;
      margin-top: 100px;
      width: 100%;
      text-align: center;
      display: block;
    }

    @include media-breakpoint-down(sm) {
      margin-top: 50px;
    }

    button {
      outline: none;
      transition: all 0.2s linear;
      border-radius: 8px;
      background-color: $colorPrimary !important;
      opacity: 0.4;
      width: 40px;
      min-width: unset;
      height: 15px;
      margin-left: 15px;

      &.-active {
        opacity: 1;
        width: 50px;
      }
    }
  }
}
