@import 'styles/_variables';
@import 'styles/_mixins';

.m-caseCard {
  width: 100%;
  max-width: 480px;
  border-radius: 15px;
  background: rgba(5, 14, 48, .6);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin: auto;
}
