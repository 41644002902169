@import 'styles/_variables';
@import 'styles/_mixins';

.m-experience {
  width: 100%;
  background-color: #f2f3f8;
  border-radius: 6px;
  padding: 15px;

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: center;

    button {
      outline: none;
      transition: all .2s linear;
      &:not(.-editing){
        opacity: 0;
      }
      svg {
        width: 15px;
        height: 15px;
      }

      &.-editing {
        svg {
          path {
            fill: $colorPrimary
          }
        }
      }
    }
  }

  &__main {
    span {
      b {
        font-weight: normal;
        margin-right: 3px;
      }
    }

    &--form {
      background: #fff;

      input {
        border-right: 1px solid #eee;
        width: 60px;
        height: 45px;
        padding: 15px;
        text-align: center;
      }

      span {
        opacity: .5;
        padding: 0 15px;
      }
    }
  }

  &:hover{
    .m-experience__header{
      button{
        opacity: 1;
      }
    }
  }

}