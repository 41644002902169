@import 'styles/_variables';
@import 'styles/_mixins';

.m-mainExpertise{
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow: hidden;
  main{
    border-bottom: 1px solid rgba(5, 14, 48, 0.1);
    margin: 0 -5px 16px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    padding-bottom: 16px;
    .chip{
      font-weight: 500;
      font-size: 12px;
      color: #050E30;
      display: flex;
      align-items: center;
      justify-content: center;

      background: #E1E1E1;
      border-radius: 40px;
      padding: 4px 8px;
      margin: 5px;
      svg{
        margin-right: 4px;
      }
    }
  }
}
