@import 'styles/_variables';
@import 'styles/_mixins';

.p-faqPage {
  padding: 100px 0;

  h2 {
    max-width: 640px;
    margin: 0 auto 60px;
    line-height: 1.3;
    text-align: center;
  }

  &__categories {
    padding: 30px 100px;
    position: relative;

    @include media-breakpoint-down(sm){
      padding: 30px;
    }

    &--item {
      border-radius: 5px;
      border: 2px solid #ddd;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      min-height: 160px;
      cursor: pointer;
      transition: all .2s linear;
      @include media-breakpoint-down(sm){
        min-width: 260px;
      }

      &Icon {
        svg {
          width: 60px;
          height: 60px;
          margin-bottom: 10px;

          path {
            fill: #aaa;
            transition: all .2s linear;
          }
        }
      }

      &Title {
        color: #222;
        font-size: 18px;
        font-weight: 600;
        line-height: 35px;
      }

      &:hover,
      &.-active {
        box-shadow: 0 7px 35px rgba(24, 161, 219, 0.17);
        border-radius: 5px;
        border: 2px solid #18a1db;
        background-color: #fff;

        .p-faqPage__categories--itemIcon {
          svg {
            path {
              fill: $colorPrimary;
            }
          }
        }
      }
    }

    .chevron-left,
    .chevron-right {
      width: 48px;
      height: 48px;
      box-shadow: 0 11px 27px rgba(24, 161, 219, 0.32);
      background-color: $colorPrimary;
      border-radius: 100%;
      position: absolute;
      top: 50%;
      left: -70px;
      margin-top: -24px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all .2s linear;

      &:hover {
        background: darken($colorPrimary, 10%);
      }

      &:before {
        content: '';
        width: 10px;
        height: 10px;
        border-left: 3px solid #fff;
        border-top: 3px solid #fff;
        display: inline-block;
        transform: rotate(-45deg);
        margin-left: 2px;
      }
    }

    .chevron-right {
      left: auto;
      right: -70px;

      &:before {
        transform: rotate(135deg);
        margin-right: 3px;
        margin-left: 0;
      }
    }

    .owl-prev,
    .owl-next {
      &.disabled {
        .chevron-left,
        .chevron-right {
          box-shadow: 0 11px 27px rgba(24, 161, 219, 0.32);
          background-color: #18a1db;
          opacity: 0.2;
          cursor: default;
        }
      }
    }
  }

  &__questions {
    padding: 30px;

    &--content {
      border-radius: 5px;
      background-color: #f8f9ff;
      padding: 30px;
      @include media-breakpoint-down(sm){
        padding: 15px;
      }
    }
  }

  &__contact {
    text-align: center;
    padding-bottom: 0;

    &--boxes {
      display: flex;
      justify-content: center;
      @include media-breakpoint-down(sm){
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 15px;
      }
    }

    &--box {
      border-radius: 5px;
      background-color: #f8f9ff;
      width: 100%;
      max-width: 450px;
      padding: 40px 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 15px;

      &Icon {
        svg {
          width: 26px;
          height: 26px;

          path {
            fill: $colorPrimary
          }
        }
      }

      &Text {
        color: #222;
        font-size: 24px;
        font-weight: 500;
        margin: 30px 0 15px;
      }

      &Subtext {
        color: #888;
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
}
