@import 'styles/_variables';
@import 'styles/_mixins';

.m-generic {
  width: 100%;
  display: flex;
  align-items: center;

  &__icon {
    background: #499FD7;
    width: 34px;
    height: 34px;
    color: #fff;
    border-radius: 100%;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
  }

  &__component {
    display: flex;
    flex-direction: column;

    input {
      height: 40px;
      border: 1px solid $colorPrimary;
      width: 100%;
      padding: 0 15px;
      border-radius: 5px;
    }

    strong {
      color: #26395A;
      font-weight: 700;
      font-size: 17px;
    }

    p {
      color: #26395A;
      font-size: 14px;
      font-weight: 500;
    }
  }

  &__action {
    margin-left: auto;
    padding-left: 15px;

    svg {
      width: 22px;
      height: 22px;
    }
  }
}
