@import 'styles/_variables';
@import 'styles/_mixins';

.m-contact {
  header {
    border-bottom: 1px solid rgba(26, 219, 116, 0.2);
    background-color: rgba(26, 219, 116, 0.2);

    span {
      color: #1adb74;
      font-size: 24px;
      font-weight: 700;
    }
  }

  main {
    padding: 20px;
  }
}