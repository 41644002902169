@import 'styles/_variables';
@import 'styles/_mixins';

.o-successfullyRegistered{
  box-shadow: 0 0 29px rgba(0, 0, 0, 0.16);
  background-color: #fff;
  margin: 100px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &__title{
    color: #11122d;
    font-size: 30px;
    font-weight: 400;
    margin-top: 40px;
    text-align: center;
  }
  &__image{
    margin: 40px 0 80px ;
  }
  &__footer{
    width: 100%;
    color: #fff;
    font-size: 15px;
    font-weight: 400;
    line-height: 28px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 40px;
    box-shadow: 0 0 29px rgba(0, 0, 0, 0.16);
    background-color: #11122d;
    border-radius: 20px 20px 0 0;
    small{
      opacity: .5;
      margin-top: 30px;
    }
  }
}
