@import 'styles/_variables';
@import 'styles/_mixins';

.o-howItWorks {
  display: flex;
  flex-direction: column;
  &__header {
    padding: 30px 0;
    text-align: center;
    h2 {
      color: $colorPrimary;
    }
  }
  &__content {
    display: flex;
    align-items: center;
    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
    &--image,
    &--video {
      max-width: 50%;
      strong {
        display: none;
      }
      @include media-breakpoint-down(sm) {
        max-width: 100%;
        strong {
          display: block;
          width: 100%;
          text-align: center;
          font-size: 22px;
          padding-bottom: 15px;
        }
      }
    }
    &--text {
      max-width: 50%;
      padding-left: 50px;
      color: #222;
      display: flex;
      flex-direction: column;
      @include media-breakpoint-down(sm) {
        max-width: 100%;
        padding: 0;
        text-align: center;
        strong {
          display: none;
        }
      }
      strong {
        font-size: 30px;
        font-weight: 600;
        padding-bottom: 15px;
      }
      p {
        margin-bottom: 30px;
        max-width: 460px;
      }
      b {
        font-size: 18px;
      }
      ul {
        display: flex;
        flex-wrap: wrap;
        padding-top: 20px;
        max-width: 340px;
        @include media-breakpoint-down(sm) {
          justify-content: space-around;
          align-items: center;
          margin: auto;
        }
        li {
          width: 100%;
          max-width: calc(50% - 15px);
          display: flex;
          align-items: center;
          margin: 0 15px 15px 0;
          @include media-breakpoint-down(sm) {
            font-weight: 600;
          }
          &:before {
            content: '';
            background: url('../../../images/icon-check.png') 0 0 no-repeat;
            width: 25px;
            height: 15px;
            display: block;
          }
        }
      }
    }
    &.-deposit,
    &.-receive,
    &.-release {
      border-top: 1px solid #d6d5d5;
      padding: 50px 0;
      justify-content: space-around;

      .o-howItWorks__content--text {
        @include media-breakpoint-down(sm) {
          order: 2;
          text-align: center;
        }
      }
      .o-howItWorks__content--image {
        max-width: 400px;
      }
      .o-howItWorks__content--image,
      .o-howItWorks__content--video {
        @include media-breakpoint-down(sm) {
          order: 1;
        }
      }
    }
    &.-deposit {
      margin-top: 50px;
      .o-howItWorks__content--text {
        text-align: right;
        padding: 0 50px 0 0;
        @include media-breakpoint-down(sm) {
          text-align: center;
          padding: 30px 0 0;
        }
      }
    }
    &.-receive {
      .o-howItWorks__content--text {
        width: 35%;
        order: 2;
        text-align: left;
        @include media-breakpoint-down(sm) {
          width: 100%;
          text-align: center;
          padding: 30px 0 0;
          p {
            margin: auto;
          }
        }
      }
      .o-howItWorks__content--image {
        order: 1;
        margin-left: 100px;
        @include media-breakpoint-down(lg) {
          margin-left: 0;
          img {
            max-width: 360px;
          }
        }
        @include media-breakpoint-down(md) {
          margin-left: 0;
          img {
            max-width: 300px;
          }
        }
      }
    }
    &.-release {
      .o-howItWorks__content--text {
        display: flex;
        flex-direction: column;
        text-align: right;
        align-items: flex-end;
        button {
          width: auto;
          display: inline-flex;
          align-items: center;
          svg {
            margin-right: 15px;
          }
        }

        @include media-breakpoint-down(sm) {
          text-align: center;
          padding: 30px 0 0;
          button {
            margin: auto;
          }
        }
      }
    }
  }
  &__modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    &--close {
      position: absolute;
      top: 30px;
      right: 30px;
      svg {
        width: 30px;
        height: 30px;
        path {
          fill: #fff;
        }
      }
    }
    &--content {
      max-width: 1280px;
      padding: 30px;
      video {
        width: auto !important;
        max-width: calc(100% - 30px);
        max-height: calc(100vh - 60px);
        margin: auto;
      }
      @include media-breakpoint-down(sm) {
        padding: 0;
      }
    }
  }
}
