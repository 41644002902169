#bank-details {
  small {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  input + small {
    margin-bottom: 0;
  }
}
