@import 'styles/_variables';
@import 'styles/_mixins';

.modalReport {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(10, 10, 10, 0.5);
  width: 100%;
  height: 100%;
  z-index: 1100;

  display: flex;
  align-items: center;
  justify-content: center;

  .box {
    background-color: white;
    border-radius: 8px;
    width: 350px;
    max-width: 90%;

    .head {
      padding: 10px;
      text-align: center;
      font-size: 14px;
      font-weight: bold;
      border-bottom: 1px solid #ddd;
      position: relative;

      .close {
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        opacity: 0.6;
        transition: all 0.15s linear;
        width: 14px;

        &:hover {
          opacity: 1;
          transition: all 0.15s linear;
        }
      }
    }

    .body {
      padding: 10px 0;
      position: relative;
      overflow: hidden;

      .reason {
        font-weight: bold;
        font-size: 14px;
        padding: 10px;
      }

      ul li {
        border-top: 1px solid #ddd;
        padding: 14px 10px;
        font-size: 13px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;

        &:hover svg {
          opacity: 0.7;
          transition: all 0.15s linear;
        }

        svg {
          width: 12px;
          opacity: 0.3;
          transition: all 0.15s linear;
          fill: #ccc;
        }

        &:last-child {
          padding-bottom: 4px;
        }
      }

      .reported {
        text-align: center;
        .title {
          font-weight: bold;
        }
        .message {
          font-size: 14px;
          margin: 10px;
        }

        form {
          display: flex;
          flex-direction: column;
          padding: 0 10px;
          textarea {
            border: 1px solid #999;
            width: 100%;
            height: 100px;
            padding: 15px;
            border-radius: 5px;
            resize: none;
          }
          button {
            border: none;
            margin-top: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 6px;
            background-color: $colorPrimary;
            padding: 10px 30px;
            color: #fff;

            &:disabled {
              background-color: #ddd;
            }

            svg {
              width: 15px;
              height: 15px;
              fill: #fff;
            }
          }
        }
      }
    }
  }
  &__loader {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 10px;
    z-index: 99;
  }
}
