@import 'styles/_variables';
@import 'styles/_mixins';


.a-starFilter {
  width: 100%;
  display: flex;
  align-items: center;
  line-height: 1;
  margin: 0 0 10px -4px !important;

  &__stars {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px 0 0;
    padding-bottom: 1px;

    svg {
      width: 16px;
      height: 16px;
      margin-right: 3px;

      path {
        fill: #ead42b !important;
      }
    }
  }

  &__label {
    color: #575759;
    font-size: 14px;
    font-weight: 500;
  }
}
