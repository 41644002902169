@import "styles/_variables";
@import "styles/_mixins";

.m-chatContact {
  position: relative;
  padding: 0 16px;
  margin-bottom: -1px;

  &__button {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    width: 100%;
    position: relative;
    outline: none !important;
    transition: all .2s linear;
    padding: 12px;
    background: transparent;
    border-radius: 8px;

    &:after {
      content: '';
      width: calc(100% - 8px);
      height: 1px;
      background: #E4EAF5;
      position: absolute;
      bottom: 0;
      right: 4px;
    }

    &--placeholder {
      display: flex;
      width: 100%;
      align-items: center;

      &-avatar {
        min-width: 60px;
        height: 60px;
        margin-right: 15px;
        position: relative;;
        border-radius: 100%;
        @extend .animated-background;
      }

      &-message {
        width: 100%;
        display: flex;
        flex-direction: column;

        &:before {
          content: '';
          width: 100%;
          height: 25px;
          margin-bottom: 10px;
          @extend .animated-background;
        }

        &:after {
          content: '';
          width: 70%;
          height: 15px;
          @extend .animated-background;
        }
      }

      &-date {
        align-self: flex-start;
        min-width: 30px;
        height: 50px;
        margin-left: 15px;
        @extend .animated-background;
      }
    }


    &--avatar {
      width: 60px;
      height: 60px;
      flex-shrink: 0;
      margin-right: 15px;
      position: relative;

      &:after {
        position: absolute;
        content: '';
        width: 16px;
        height: 16px;
        border: 2px solid #fff;
        background-color: #938F8F;
        border-radius: 100%;
        right: 2px;
        bottom: 2px;
      }

      img {
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-radius: 100%;
      }

      &.-away,
      &.-online {
        &:after {
          display: block;
        }
      }

      &.-away {
        &:after {
          background: #facf00;
        }
      }

      &.-online {
        &:after {
          background-color: #00BA88;
        }
      }
    }

    &--info {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 24px;

      small {
        width: 100%;
        font-weight: 500;
        font-size: 11px;
        color: #828282;
        display: block;
      }

      strong {
        max-width: 100%;
        font-weight: 500;
        font-size: 16px;
        color: #499FD7;
      }

      p {
        max-width: 100%;
        opacity: 0.7;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 500;
        font-size: 12px;
        color: #828282;
      }
    }
  }

  &.-active {
    .m-chatContact__button {
      border:none;
      background: #499FD7;

      &--avatar{
        &:after {
          border-color: #499FD7;
        }
      }
      &--info {
        small, strong, p {
          color: #fff;
        }
      }
    }
  }

  &.-newMessages {
    position: relative;

    .m-chatContact__button {

      &:before {
        content: '';
        width: 14px;
        height: 14px;
        flex: 0;
        background: #0479fc;
        border-radius: 100%;
        position: absolute;
        top: 50%;
        right: 16px;
        margin-top: -7px;
      }
    }
  }

}
