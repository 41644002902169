@import 'styles/_variables';
@import 'styles/_mixins';

.m-inviteYourFirm {
  background-color: $colorPrimary;
  padding: 20px;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  align-items: center;
  text-align: center;
  width: 100%;

  p {
    color: #11122d;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 16px;
  }

  &__icon {
    margin: 10px 0 30px;
  }

  &__form {
    position: relative;
    display: flex;
    overflow: hidden;
    border-radius: 6px;
    height: 45px;

    input {
      width: 100%;
      border: none;
      padding: 0 15px;
    }

    button {
      background-color: #050e30;
      color: #ffffff;
      font-size: 16px;
      font-weight: 500;
      flex: 1;
      width: 100px;
      min-width: 100px;
    }
  }
}
