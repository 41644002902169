@import 'styles/_variables';
@import 'styles/_mixins';

.o-resultsSearchBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  line-height: 1;
  margin: 30px 0;

  @include media-breakpoint-up(md) {
    height: 70px;
    box-shadow: 0 9px 23px rgba(171, 171, 171, 0.14);
    border: 1px solid #ddd;
    background-color: #f8f9ff;
    border-radius: 5px;
  }

  @include media-breakpoint-down(md) {
    &.-hide {
      display: none;
    }
  }
  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }

  &__action,
  &__field {
    width: 100%;
    height: 68px;
    max-width: calc(33% - 80px);
    overflow: hidden;
    border-radius: 5px;

    @include media-breakpoint-down(md) {
      max-width: calc(33% - 30px);
    }

    &.-postcode{
      @include media-breakpoint-down(md) {
        max-width: calc(24% - 30px);
      }
    }

    @include media-breakpoint-down(sm) {
      max-width: 100% !important;
      margin-bottom: 15px;
      border: 1px solid #ddd;
    }
  }

  &__action {
    width: 240px;
    height: 70px;
    border-radius: 0 5px 5px 0;

    @include media-breakpoint-down(md) {
      width: 120px;
    }
    @include media-breakpoint-down(sm) {
      width: 100%;
      border-radius: 5px;
      border: none;
      margin-bottom: 0;
      button {
        width: 100%;
      }
    }
  }

  &__divider {
    width: 1px;
    height: 50px;
    background: #ddd;
    display: inline-block;
    min-width: 1px;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
}
