@import 'styles/_variables';
@import 'styles/_mixins';

.o-lawyerProfile {
  &__container {
    max-width: 1200px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 50px 0;
    @include media-breakpoint-down(sm) {
      flex-direction: column;
      padding: 30px 0;
    }
  }

  &__aside {
    width: 100%;
    max-width: 360px;
    padding-top: 15px;
    padding-right: 15px;

    @include media-breakpoint-down(lg) {
      max-width: 320px;
      margin-left: 15px;
    }

    @include media-breakpoint-down(sm) {
      max-width: 100%;
      padding-right: 30px;
    }

    &--box {
      width: 100%;
      border-radius: 10px;
      background: rgba(5, 14, 48, 0.6);
      padding: 20px;
      margin-bottom: 25px;
      display: flex;
      flex-direction: column;

      &.-center {
        justify-content: center;
        align-items: center;
      }

      &-avatar {
        border-radius: 100%;
        margin-top: -50px;
        border: 3px solid #00ba88;
        padding: 7px;
        position: relative;

        .-indicator {
          position: absolute;
          top: 15px;
          right: 10px;
          width: 22px;
          height: 22px;
          border-radius: 100%;
          background: #00ba88;
          border: 3px solid #26395a;
          display: flex;
          box-shadow: 0 0 7px rgba(255, 255, 255, 0.3);
        }
      }

      &-name {
        color: #fff;
        font-weight: 500;
        font-size: 22px;
        text-transform: uppercase;
        margin: 15px 0 5px;
        line-height: 1;
      }
    }

    .a-whiteBox {
      width: 100%;
      max-width: 100%;
      margin: 15px 0;

      &__header {
        &--icon {
          color: #499fd7;
          padding: 4px;

          svg {
            width: 100%;
            height: 100%;
          }
        }

        &--actions {
          display: flex;
          align-items: center;
          justify-content: center;

          button {
            svg {
              width: 22px;
              height: 22px;
              color: #fff;
            }
          }
        }
      }

      &__main {
        min-height: 60px;
        justify-content: flex-start;

        input {
          border-radius: 10px;
          padding: 0 16px;
          height: 40px;
          outline: none;
          background: transparent;
          border: 1px solid rgba(255, 255, 255, 0.7);
          color: #fff;
          width: 100%;

          &:disabled {
            margin-bottom: 16px;
            background: rgba(255, 255, 255, 0.3);
            opacity: 0.5;
            cursor: not-allowed;
          }
        }

        span {
          color: #fff;
          font-size: 14px;
          width: 100%;
        }

        small {
          color: #fff;
          font-size: 12px;
          line-height: 1;
        }
      }

      &.-bankAccount {
        .a-whiteBox__main {
          small {
            color: #fff;

            &:not(.text-xs) {
              font-size: 14px;
            }
          }

          ul {
            width: 100%;

            li {
              margin-top: 16px;
              width: 100%;
              background: rgba(255, 255, 255, 0.2);
              display: flex;
              flex-direction: column;
              border-radius: 10px;
              font-size: 15px;
              color: #fff;
              line-height: 1;

              input {
                margin: 16px;
                max-width: calc(100% - 32px);
              }

              span {
                font-weight: 500;
                width: 100%;
                padding: 16px;
                border-bottom: 1px solid rgba(255, 255, 255, 0.1);
              }

              b {
                font-weight: 500;
                padding: 16px;
              }

              small {
                color: #fff;
                padding: 0 16px 16px;
                font-size: 12px;
                line-height: 1.2;
              }
            }
          }
        }
      }

      &.-linkedIn {
        .a-whiteBox {
          &__main {
            a {
              display: flex;
              align-items: center;
              justify-content: center;
              border: 1px solid rgba(255, 255, 255, .5);
              border-radius: 10px;
              width: 100%;
              transition: all .2s linear;

              .-icon {
                padding: 8px;
                color: #fff;
              }

              span {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                padding: 8px;
                border-left: 1px solid rgba(255, 255, 255, .5);
              }

              &:hover {
                background: #499fd7;
              }
            }

            button {
              border: 2px solid #ffffff;
              border-radius: 40px;
              font-weight: 500;
              font-size: 14px;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 8px 16px;
              color: #fff;
              transition: all .2s linear;

              svg {
                width: 24px;
                height: 24px;
                margin-left: 6px;
              }

              &:hover {
                background: rgba(255, 255, 255, .1);
              }
            }
          }
        }
      }
    }
  }

  &__content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;

    max-width: calc(100% - 360px);

    @include media-breakpoint-down(lg) {
      max-width: calc(100% - 320px);
    }
    @include media-breakpoint-down(sm) {
      max-width: 100%
    }

    .a-whiteBox {
      max-width: calc(50% - 30px);
      @include media-breakpoint-down(md) {
        max-width: 100%;
      }


      &.-expertise{
        max-width: 100%;
        .a-whiteBox__main{
          min-height: unset !important;
        }
      }

      &.-hourlyRates{
        min-height: 350px;
      }

      &__header {
        &--icon {
          svg {
            color: #499FD7;
          }
        }

        &--actions {
          button {
            svg {
              width: 22px;
              height: 22px;
              color: #26395A;
            }
          }
        }
      }

      &.-aboutMe {
        .a-whiteBox {
          &__main {
            display: flex;
            flex-direction: column;
          }
        }
      }

      &.-myTeam {
        max-width: 100%;

        .a-whiteBox {
          &__header {
            &--actions {
              button {
                display: flex;
                align-items: center;
                justify-content: center;
                border: 2px solid #26395A;
                border-radius: 40px;

                font-weight: 500;
                font-size: 14px;
                color: #26395A;
                padding: 8px 16px;
                transition: all .2s linear;

                svg {
                  margin-left: 6px;
                  transition: all .2s linear;
                }

                &:hover {
                  border-color: $colorPrimary;
                  color: $colorPrimary;

                  svg {
                    color: $colorPrimary;
                  }
                }
              }
            }
          }

          &__main {
            min-height: 140px;
          }
        }
      }

      &.-expertise {
        .a-whiteBox {
          &__main {
            align-items: flex-start;
          }
        }
      }

      &.-hourlyRates {
        .a-whiteBox {
          &__main {
            min-height: 100px;
            align-items: flex-start;

            main {
              width: 100%;

              > div:not(:last-child) {
                border-bottom: 1px dashed #E9EBEF;
              }
            }
          }
        }
      }

      &.-availability {
        main {
          width: 100%;
        }
      }
    }
  }

}

/*

.o-lawyerProfile {
  width: 100%;
  position: relative;
  background-color: #f2f3f8;
  padding: 10px 30px 100px;

  &__container {
    max-width: 1200px;
    margin: auto;
    display: flex;
    align-items: flex-start;

    @include media-breakpoint-down(md) {
      flex-direction: column;
    }
  }

  &__aside {
    width: 100%;
    max-width: 370px;
    margin-top: 40px;
    margin-right: 20px;
    @include media-breakpoint-down(md) {
      max-width: 100%;
      &--inviteYourFirm{
        display: none;
      }
    }
    &--box{
      background: #ffffff;
      border-radius: 6px;
      position: relative;
      z-index: 2;
      padding: 20px 20px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      @include media-breakpoint-down(md) {
        max-width: 100%;
      }
    }

    &--avatar {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 3px solid $colorPrimary;
      width: 200px;
      height: 200px;
      border-radius: 100%;
      position: relative;

      &:before {
        content: '';
        width: 17px;
        height: 17px;
        border-radius: 100%;
        position: absolute;
        bottom: 19px;
        right: 19px;
        background-color: #12b87f;
        border: 2px solid #fff;
      }
    }

    &--info {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;

      h2 {
        color: #050e30;
        font-size: 24px;
        font-weight: 600;
        text-transform: uppercase;
      }

      &-rating {
        color: #424143;
        font-size: 16px;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          margin-left: 5px;
          padding-top: 3px;
        }
      }
    }

    &--form {
      width: 100%;
      margin-top: 30px;

      &-box {
        width: 100%;
        display: flex;
        flex-direction: column;
        max-width: 100%;
        background-color: #f2f3f8;
        border-radius: 6px;
        padding: 20px;
        margin-bottom: 20px;

        header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 20px;

          strong {
            color: #050e30;
            font-size: 16px;
            font-weight: 600;
            line-height: 21px;
          }

          button {

            opacity: 0;
            outline: none;
            transition: all .2s linear;

            svg {
              width: 16px;
              height: 16px;
            }

            &.-editing {
              opacity: 1;
              svg {
                path {
                  fill: $colorPrimary
                }
              }
            }
          }
        }

        main {
          display: flex;
          flex-direction: column;
          label{
            margin-top: 15px;
          }

          //span {
          //  color: #53586e;
          //  font-size: 16px;
          //  font-weight: 500;
          //
          //  white-space: nowrap;
          //  overflow: hidden;
          //  text-overflow: ellipsis;
          //}


          input {
            border: 1px solid $colorPrimary;
            padding: 5px 10px;
            border-radius: 6px;
          }
          ul{
            li{
              display: flex;
              flex-direction: column;
              margin-bottom: 5px;
              padding: 10px;
              border-radius: 6px;
              background: #fefefe;
              b{
              }
              span{
                font-size: 12px;
              }
            }
          }
          &.linkedin{

            button {
              display: inline-flex;
              align-items: center;

              span {
                margin-left: 15px;
                color: #53586e;
                font-size: 16px;
                font-weight: 600;
                line-height: 1;
              }
            }
          }
        }
        .link{
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 14px;
        }
        &:hover{
          button{
            opacity: 1;
          }
        }
      }
    }
    &--sra{
      width: 100%;
      text-align: center;
      padding: 0 0 20px;
    }
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    width: 100%;

    &--inviteYourFirm{
      display: none;
      width: 100%;
      @include media-breakpoint-down(md){
        display: block;
      }
    }

    &--filter,
    &--box {
      width: 100%;
      max-width: calc(50% - 40px);
      margin: 40px 20px 0;
      background: #fff;
      border-radius: 6px;
      position: relative;
      z-index: 2;
      @include media-breakpoint-down(lg) {
        max-width: 100%;
      }

      @include media-breakpoint-down(md) {
        margin: 40px 0 0;
      }
      @include media-breakpoint-down(xs) {
        max-width: 100%
      }
      strong{
        color: $colorPrimary;
      }
    }

    &--filter {
      max-width: 100%;
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      button {
        margin-left: 15px;
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
        color: #424143;

        &.-active {
          color: $colorPrimary;
          text-decoration: underline;
        }
      }
    }

    &--box {
      overflow: hidden;

      header {
        border-bottom: 1px solid #e5e6ea;
        padding: 10px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
          color: $colorPrimary;
          font-size: 24px;
          font-weight: 400;
        }

        svg {
          min-width: 24px;

          path {
            fill: $colorPrimary !important;
          }
        }

        @include media-breakpoint-down(sm) {
          span {
            font-size: 18px;
          }
        }
      }

      main {
        max-width: 100%;

        @include media-breakpoint-down(xs) {
          overflow-x: auto;
        }

        &.-withSpace {
          padding: 20px;
        }

        table {
          width: 100%;
          max-width: 100%;
          border-collapse: collapse;

          thead {
            tr {
              th {
                padding: 15px;
                color: #050e30;
                font-size: 16px;
                font-weight: 600;
                border: 1px solid #e5e6ea;
                border-top: none;
                text-align: left;
                border-left: none;
                &:last-child{
                  border-right: none;
                }

                &:last-child {
                  text-align: right;
                }
              }
            }
          }

          tbody {
            tr {
              td {
                padding: 15px;
                color: #050e30;
                font-size: 14px;
                font-weight: 500;
                border: 1px solid #e5e6ea;
                border-left: none;
                &:last-child{
                  border-right: none;
                }

                button {
                  display: flex;
                  align-items: center;
                  border: 1px solid #a2a2a2;
                  background-color: #fff;
                  padding: 5px 10px;
                  border-radius: 5px;

                  svg {
                    width: 12px;
                    height: 12px;
                    margin-right: 5px;
                  }
                }
              }
            }
          }
        }
      }

      &-item {
        &.-full {
          width: 100%;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .-payments {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
      }

      .-reviews {
        button {
          width: 100%;
          text-align: center;
          padding: 10px 20px;
          color: #050e30;
          font-size: 16px;
          font-weight: 600;
          transition: all .2s linear;

          &:hover {
            background: $colorPrimary;
            color: #fff;
          }
        }
      }
    }
  }
}
*/
