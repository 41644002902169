@import 'styles/_variables';
@import 'styles/_mixins';

.p-profilePage {
  margin-top: -30px;
  position: relative;
  min-height: calc(100vh - 100px);

  &__header {
    background-color: #14132a;
    background-size: cover;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 30px 80px;
    position: relative;

    &--tabs {
      position: absolute;
      left: 50%;
      bottom: 0;
      width: 100%;
      max-width: 1170px;
      display: flex;
      justify-content: space-between;
      transform: translate(-50%, 0);

      button {
        color: #fff;
        font-size: 18px;
        font-weight: 500;
        text-align: center;
        width: 100%;
        border-bottom: 8px solid #14132a;
        padding: 15px;
        outline: none;
        transition: all 0.2s linear;
        &.-active,
        &:hover {
          border-bottom-color: #18a1db;
        }
      }
    }

    &Content {
      width: 100%;
      max-width: 1170px;
      border-radius: 10px;
      background-color: #ffffff;
      display: flex;
      align-items: center;
      padding: 30px 60px;
      position: relative;
      @include media-breakpoint-down(md) {
        flex-direction: column;
        text-align: center;
        padding: 30px;
      }
      @include media-breakpoint-down(xs) {
        padding: 30px;
      }

      //&--bookmark {
      //  position: absolute;
      //  top: 20px;
      //  right: 20px;
      //
      //  svg {
      //    width: 32px;
      //    height: 42px;
      //    @include media-breakpoint-down(xs) {
      //      width: 20px;
      //      height: 24px;
      //    }
      //  }
      //}

      &--image {
        min-width: 320px;
        height: 320px;
        position: relative;
        margin-right: 40px;

        @include media-breakpoint-down(md) {
          margin: 0 0 40px;
        }
        @include media-breakpoint-down(xs) {
          min-width: 220px;
          height: 220px;
        }

        &:after,
        &:before {
          content: '';
          width: 25px;
          height: 25px;
          background: #ccc;
          border-radius: 100%;
          display: inline-block;
          position: absolute;
          bottom: 36px;
          right: 36px;

          @include media-breakpoint-down(xs) {
            bottom: 28px;
            right: 28px;
          }
        }

        img {
          min-width: 320px;
          height: 320px;
          border: 4px solid #ccc;
          overflow: hidden;
          border-radius: 100%;
          @include media-breakpoint-down(xs) {
            min-width: 210px;
            height: 210px;
          }
        }

        &.-online {
          &:before {
            background: #24e873;
          }

          &:after {
            background: radial-gradient(
              circle,
              rgba(255, 255, 255, 0.5) 0%,
              #2bbd19 100%
            );
            -webkit-animation: flash linear 1s infinite;
            animation: flash linear 1s infinite;
          }

          img {
            border-color: #24e873;
          }
        }

        &.-away {
          &:before {
            background: #dca71c;
          }

          &:after {
            background: radial-gradient(
              circle,
              rgba(255, 255, 255, 0.5) 0%,
              #dca71c 100%
            );
            -webkit-animation: flash linear 1s infinite;
            animation: flash linear 1s infinite;
          }

          img {
            border-color: #dca71c;
          }
        }
      }

      &--info {
        width: 100%;
        display: flex;
        flex-direction: column;

        @include media-breakpoint-down(xs) {
          max-width: 100%;
        }

        &-title {
          color: #202233;

          span {
            font-weight: 500;
            font-size: 22px;
            @include media-breakpoint-down(sm) {
              font-size: 16px;
            }
          }

          h2 {
            font-weight: 700;
            font-size: 55px;
            @include media-breakpoint-down(sm) {
              font-size: 45px;
            }
            @include media-breakpoint-down(xs) {
              font-size: 36px;
              line-height: 1.3;
              margin: 15px 0;
            }
          }
        }

        &-reviews {
          width: 100%;
          margin: 15px 0 30px;
          display: flex;
          align-items: center;
          line-height: 1;

          @include media-breakpoint-down(md) {
            justify-content: center;
          }

          @include media-breakpoint-down(xs) {
            flex-direction: column;
          }

          button {
            margin-left: 15px;
            color: #18a1db;
            font-size: 20px;
            font-weight: 700;
            padding: 0;
            outline: none;

            @include media-breakpoint-down(xs) {
              margin: 15px 0 0;
            }
          }
        }

        &-organization {
          span {
            color: #202233;
            font-size: 25px;
            font-weight: 500;
            line-height: 30px;

            @include media-breakpoint-down(xs) {
              font-size: 20px;
              line-height: 1.3;
            }
          }
        }

        &-actions {
          display: flex;
          align-items: flex-start;
          margin-top: auto;
          padding-top: 40px;
          width: 100%;
          @include media-breakpoint-down(md) {
            justify-content: center;
          }
          @include media-breakpoint-down(sm) {
            flex-wrap: wrap;
          }
          &-hourlyRate,
          &-fixedFee {
            display: flex;
            align-items: center;
            flex-direction: column;
            margin-right: 30px;

            @include media-breakpoint-down(sm) {
              width: 50%;
              margin: 0;
            }
            span {
              opacity: 0.7;
              color: #202233;
              font-size: 16px;
              font-weight: 500;
              padding-bottom: 15px;
              @include media-breakpoint-down(sm) {
                font-size: 14px;
              }
            }
            strong {
              color: #17ef64;
              font-size: 45px;
              font-weight: 700;
              line-height: 1;
              @include media-breakpoint-down(sm) {
                font-size: 26px;
              }
            }
            svg {
              width: 35px;
              height: 35px;
              @include media-breakpoint-down(sm) {
                width: 24px;
                height: 24px;
              }
              &.positive {
                path {
                  fill: #17ef64;
                }
              }
              &.negative {
                path {
                  fill: #ccc;
                }
              }
            }
          }
          &-hourlyRate {
            border-right: 1px solid #ccc;
            padding-right: 30px;
          }
        }
        &-buttons {
          margin-left: auto;
          display: flex;
          flex-direction: column;
          @include media-breakpoint-down(sm) {
            flex-direction: row;
            justify-content: space-around;
            margin-top: 30px;
          }
          @include media-breakpoint-down(xs) {
            flex-direction: column;
            width: 100% !important;
          }
          > span,
          button {
            height: 70px;
            padding: 0 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            border: none;
            margin-bottom: 15px;
            transition: all 0.2s linear;
            font-size: 20px;
            font-weight: 600;

            @include media-breakpoint-down(sm) {
              margin: 15px 15px 10px;
            }

            @include media-breakpoint-down(xs) {
              width: 100% !important;
              margin: 15px 0 5px;
            }
            svg {
              width: 30px;
              max-width: 30px;
              max-height: 30px;
              margin-right: 15px;

              path {
                transition: all 0.2s linear;
                fill: $colorPrimary;
              }
            }

            span {
              transition: all 0.2s linear;
            }
            &.-phone {
              border-radius: 4px;
              background-color: #18a1db;
              color: #fff;
              &:hover {
                background-color: #126f97;
              }

              svg {
                path {
                  fill: #fff;
                }
              }
            }
            &.-message {
              border-radius: 4px;
              background-color: #1cd25d;
              color: #fff;
              &:hover {
                background-color: #127937;
              }

              svg {
                path {
                  fill: #fff;
                }
              }
            }
          }
        }
      }
    }
  }

  &__content {
    width: 100%;
    max-width: 1170px;
    padding: 15px;
    margin: auto;
  }

  &__highlights {
    display: flex;
    justify-content: space-between;
    padding: 30px 0 0;

    @include media-breakpoint-down(md) {
      margin: 30px 15px 0;
      flex-wrap: wrap;
    }
    @include media-breakpoint-down(sm) {
      padding: 0;
    }

    &--item {
      width: 100%;
      max-width: calc(25% - 20px);
      display: flex;
      flex-direction: column;
      text-align: center;
      box-shadow: 0 0 46px rgba(0, 0, 0, 0.09);
      border-radius: 8px;
      background-color: #ffffff;
      align-items: center;
      justify-content: center;
      padding: 30px;
      transition: all 0.2s linear;

      @include media-breakpoint-down(md) {
        max-width: calc(50% - 20px);
        margin-bottom: 40px;
      }
      @include media-breakpoint-down(xs) {
        max-width: 100%;
        margin-bottom: 40px;
      }

      &-icon {
        width: 90px;
        height: 90px;
        border-radius: 100%;
        padding: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 15px;
        transition: all 0.2s linear;

        svg {
          max-width: 40px;
          max-height: 40px;
        }
      }

      &-text {
        font-size: 25px;
        font-weight: 600;
        line-height: 35px;
        color: #161622;
        transition: all 0.2s linear;
        @include media-breakpoint-down(lg) {
          font-size: 20px;
        }
      }

      &:nth-child(1) {
        .p-profilePage__highlights--item-icon {
          background-color: #f7beb6;

          svg {
            path {
              fill: #f73e61;
            }
          }
        }
      }

      &:nth-child(2) {
        .p-profilePage__highlights--item-icon {
          background-color: #ffeaab;

          svg {
            path {
              fill: #f4c317;
            }
          }
        }
      }

      &:nth-child(3) {
        .p-profilePage__highlights--item-icon {
          background-color: #e8ffe8;

          svg {
            path {
              fill: #37bf3b;
            }
          }
        }
      }

      &:nth-child(4) {
        .p-profilePage__highlights--item-icon {
          background-color: #f1f8ff;

          svg {
            path {
              fill: #18a1db;
            }
          }
        }
      }

      &:hover {
        background: $colorPrimary;

        span {
          color: #fff;
        }

        .p-profilePage__highlights--item-icon {
          background: #fff;
        }
      }
    }
  }

  &__info {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include media-breakpoint-down(md) {
      margin: 0 15px;
    }

    &Box {
      width: 100%;
      max-width: calc(50% - 15px);
      box-shadow: 0 0 46px rgba(0, 0, 0, 0.09);
      background-color: #fff;
      border-radius: 6px;
      padding: 30px 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 30px;

      @include media-breakpoint-down(md) {
        max-width: 100%;
      }

      @include media-breakpoint-down(xs) {
        padding: 20px;
      }

      h3 {
        color: #222222;
        font-size: 45px;
        font-weight: 600;
        margin-bottom: 30px;
        @include media-breakpoint-down(sm) {
          font-size: 26px;
        }
      }

      p {
        color: #3d3d52;
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        white-space: pre-line;
      }

      button {
        margin-top: 30px;
        box-shadow: unset !important;
        padding: 20px !important;
      }

      ul {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

        li {
          min-width: 190px;
          display: flex;
          align-items: center;
          max-width: 50%;
          width: 100%;
          line-height: 1;
          margin-bottom: 30px;

          @include media-breakpoint-down(md) {
            width: 100%;
          }
          @include media-breakpoint-down(xs) {
            max-width: 100%;
          }

          svg {
            width: 23px;
            min-width: 23px;
            height: 23px;
            margin-right: 10px;

            path {
              fill: $colorPrimary;
            }
          }

          span {
            color: #3d3d52;
            font-size: 18px;
            font-weight: 400;
            line-height: 1.2;

            @include media-breakpoint-down(sm) {
              font-size: 16px;
            }
          }

          button {
            padding: 0 !important;
            margin: 0;
            color: #18a1db;
            font-size: 20px;
            font-weight: 500;
          }
        }
      }

      &.-row {
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        .p-profilePage__infoBox {
          &--icon {
            min-width: 70px;
            min-height: 70px;
            border-radius: 100%;
            background-color: #eefaff;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          &--text {
            color: #26263e;
            font-size: 30px;
            font-weight: 600;
            margin-left: 30px;

            @include media-breakpoint-down(xs) {
              font-size: 20px;
              line-height: 1.3;
            }
          }
        }
      }
    }
  }

  &__getDirections {
    @include media-breakpoint-down(md) {
      padding: 0;
    }
  }

  &__team {
    padding: 100px 30px;

    @include media-breakpoint-down(md) {
      padding: 100px 0;
    }
    @include media-breakpoint-down(xs) {
      padding: 100px 15px;
    }

    &--title {
      color: #222222;
      font-size: 45px;
      font-weight: 600;
      margin-bottom: 50px;
      width: 100%;
      text-align: center;
    }
  }

  &__reviews {
    margin-top: 50px;
    padding: 50px 30px;
    background: #efefef;

    @include media-breakpoint-down(sm) {
      padding: 30px;
    }

    &--title {
      margin-bottom: 50px;
      h2 {
        color: #222222;
        font-size: 45px;
        font-weight: 600;
        width: 100%;
        text-align: center;
      }
      &-rating {
        display: flex;
        align-items: center;
        justify-content: center;
        &Stars {
          padding-right: 15px;
          padding-bottom: 5px;
        }
        small {
          margin-left: 5px;
          color: #303030;
          font-weight: 400;
        }
      }
    }
  }
}
