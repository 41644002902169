@import "styles/_variables";
@import "styles/_mixins";

.m-chatActions {
  z-index: 3;

  &__dropdown {
    width: 100%;
    background: #26395A;
    position: absolute;
    display: flex;
    flex-direction: column;
    padding: 20px;
    opacity: 0;
    transition: all .2s linear;
    pointer-events: none;
    bottom: 0;
    left: 0;
    z-index: 3;

    &.-show {
      opacity: 1;
      pointer-events: initial;
      bottom: 65px;
    }

    &--actions {
      display: flex;
      flex-wrap: wrap;
      margin-top: 16px;
      @include media-breakpoint-down(md) {
        margin-top: 0;
      }
    }

    span {
      font-weight: 500;
      font-size: 12px;
      color: #869DC5;
    }


    button {
      display: flex;
      align-items: center;
      margin-right: 16px;
      outline: none;
      border: 2px solid #fff;
      border-radius: 20px;
      padding: 8px 16px;
      @include media-breakpoint-down(md) {
        padding: 8px 12px;
        margin-right: 8px;
        margin-top: 16px;
      }

      span {
        font-weight: 500;
        font-size: 14px;
        color: #fff;
        @include media-breakpoint-down(md) {
          font-size: 12px;
        }
      }

      svg {
        width: 20px;
        height: 20px;
        margin-right: 8px;
        @include media-breakpoint-down(md) {
          width: 16px;
          height: 16px;
        }
      }

      transition: all .2s linear;

      &:hover {
        border-color: #499FD7;
        background: #499FD7;
      }
    }
  }

  &__trigger {
    transition: all .2s linear;
    outline: none !important;

    &.-open {
      transform: rotate(45deg);
    }
  }
}
